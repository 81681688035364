<template>
  <section style="margin: 0 24px">
    <section class="form-style">
      <!-- 头部 -->
      <a-form layout="inline" ref="headFormRef" :model="headFormState">
        <!-- 搜索框 -->
        <a-form-item label="" @click="clickFocus" name="searchVal">
          <a-input
            class="form-input_365px"
            allowClear
            v-model:value="headFormState.searchVal"
            :maxlength="20"
            ref="inputbox"
            placeholder="请输入流程主题、申请人或印章名称搜索"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <!-- 印章类型 -->
        <a-form-item label="印章类型：" name="sealType" v-if="!isShow">
          <a-select v-model:value="headFormState.sealType" allowClear class="form-select" placeholder="请选择">
            <a-select-option v-for="(value, key) in sealTypeList" :key="key" :value="key">
              {{ value.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="审批类型：">
          <a-select v-model:value="sealTypes" allowClear class="form-select" placeholder="请选择">
            <a-select-option v-for="item in sealStatus" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item> -->
        <!-- 审批状态 -->
        <a-form-item label="审批状态：" name="approvalStatus">
          <a-select v-model:value="headFormState.approvalStatus" allowClear class="form-select" placeholder="请选择">
            <a-select-option v-for="item in approvalStatusList" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 重置/查询 -->
        <a-form-item class="form-btns">
          <a-button @click="reset">重置</a-button>
          <a-button class="form-btn" @click="searchList">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">用印列表</span>
      </div>
      <a-table
        :columns="columns"
        :pagination="pagination"
        :dataSource="dataSource"
        @change="handleTableChange"
        :loading="tableLoading"
        :rowKey="r => `${r.documentId}-${r.sealId}`"
        ref="tableRef"
        :scroll="{ x: 400 }"
      >
        <!-- 序号 -->
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <template #name="{ text }">
          {{ text.split(',')[0] }}
        </template>
        <!-- 流程主题 -->
        <template #fileName="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 印章名称 -->
        <template #sealNames="{ text }">
          <span class="seal-list" :title="text">{{ text }}</span>
        </template>
        <!-- 印章类型 -->
        <template #sealTypeName="{ text }">
          <span class="sealTypeName" :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 当前审批人 -->
        <template #examineStaffName="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 预计用印日期 -->
        <template #sealTime="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 审批状态 -->
        <template #examineStatus="{ text }">
          <span class="dotted" :style="{ background: examineStatusObj[text > 2 ? 1 : text].color }"></span>
          <span>{{ examineStatusObj[text > 2 ? 1 : text].name }}</span>
        </template>
        <!-- 操作 -->
        <template #action="{ record }">
          <a @click="openModal(record)" class="table-text-color">查看</a>
        </template>
      </a-table>
    </section>
    <!-- 实体章弹窗 -->
    <modal
      v-model:visible="visible"
      @modelSubmit="addApply"
      :currentLoading="currentLoading"
      :current="current"
      :hasFoot="false"
    />
    <!-- 电子章弹窗 -->
    <electronicModal
      v-model:visible="isVisible"
      @modelSubmit="addApply"
      :currentLoading="currentLoading"
      :current="detail"
      :hasFoot="false"
    ></electronicModal>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { apprpvedColumns, apprpvedColumn } from '../columns'
import modal from '../components/modal'
import electronicModal from '../components/electronicModal'
// import tableHeader from '@/views/components/tableHeader'
import { getApprovedList, getApprovedDetail, getElectronicApprovedDetail } from '@/apis/approval'
import { getApplyListDetail, getFingerprintDetail, addApply } from '@/apis/seal'
import { examineStatusObj, paginationParams } from '@/utils/constData'
import { setTablePageFooterStyle } from '@/utils/utils'
import { useRoute } from 'vue-router'
//判断企业版/政务版
import { getDetailsApplyType } from '@/apis/user'
import moment from 'moment'
const sealStatus = [
  {
    id: 1,
    name: '用印审批'
  },
  {
    id: 2,
    name: '归档审批'
  }
]
const approvalStatusList = [
  {
    id: 1,
    name: '已同意'
  },
  {
    id: 2,
    name: '已拒绝'
  }
]
const sealTypeList = {
  1: { name: '实体章' },
  2: { name: '电子章' }
}
export default defineComponent({
  components: {
    modal,
    electronicModal
    // tableHeader
  },
  setup() {
    const isShow = ref(false)
    const route = useRoute()
    const inputbox = ref(null)
    const tableRef = ref()
    const headFormRef = ref()
    const headFormState = reactive({
      searchVal: undefined, //查询
      sealType: undefined, //印章类型
      approvalStatus: undefined //审批状态
    })
    const state = reactive({
      columns: apprpvedColumns,
      documentId: undefined,
      apprpvedColumns,
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      visible: false,
      isVisible: false,
      current: undefined,
      detail: undefined,
      tableLoading: true,
      currentLoading: false,
      currentNum: 1
    })

    const getList = async () => {
      // 列表展示数据
      state.tableLoading = true
      state.dataSource = []
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        search: headFormState.searchVal,
        examineStatus: headFormState.approvalStatus,
        examineType: state.sealTypes,
        documentId: state.documentId,
        sealType: headFormState.sealType
      }
      const res = await getApprovedList(params)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
      state.documentId = undefined
    }
    const openModal = async current => {
      console.log('current', current)
      if (current.sealType === 1) {
        //实体章
        state.visible = true
      } else if (current.sealType === 2) {
        //电子章
        state.isVisible = true
      }
      state.currentLoading = true
      let res = undefined
      if (current.status === 1) {
        //特权用户用印
        res = await getFingerprintDetail({ documentId: current.documentId })
      } else if (current.status === 2) {
        //普通用印
        // res = await getApplyListDetail({ documentId: current.documentId })
        res = await getApprovedDetail({ viceId: current.viceId })
      } else if (current.status === 3) {
        //电子签章
        res = await getElectronicApprovedDetail(current)
        console.log('电子签章详情', res)
      }
      if (current.status === 1 || current.status === 2) {
        state.current = {
          ...res.data,
          // examineType: current.examineType,
          documentId: current.documentId,
          status: current.status
        }
        state.current.useCount =
          current.status === 2 ? res.data.sealShow.reduce((acct, item) => acct + item.practicalNumber, 0) : 1
      } else if (current.status === 3) {
        state.detail = {
          ...res.data
        }
        state.detail.expireTime = moment(state.detail.expireTime).format('YYYY-MM-DD')
      }
      state.currentLoading = false
    }
    //搜索
    const searchList = () => {
      // 清空前后空格
      if (headFormState.searchVal) {
        headFormState.searchVal = headFormState.searchVal.replace(/\s*/g, '')
      }
      // 查询列表数据
      state.pagination.current =
        !headFormState.approvalStatus && !headFormState.searchVal && !headFormState.sealType ? state.currentNum : 1
      getList()
    }
    //重置
    const reset = () => {
      console.log('headFormRef', headFormRef.value)
      headFormRef.value.resetFields()
      getList()
    }
    const handleTableChange = ({ current, pageSize }) => {
      // 列表的页码change事件
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.dataSource = []
      getList()
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }

    // 第三方跳转
    const thirdparty = () => {
      if (route.query.documentId) {
        openModal({
          status: route.query.sealType === '1' ? 2 : 3,
          documentId: route.query.documentId,
          flag: route.query.flag,
          sealId: route.query.sealId,
          sealType: route.query.sealType === '1' ? 1 : 2
        })
        state.documentId = route.query.documentId
      }
    }
    // 是否为企业版 列表数据不同
    const detailType = ref()
    const getDetailsType = async () => {
      const res = await getDetailsApplyType()
      detailType.value = res.data
      console.log('detailType的值', detailType.value)
      if (detailType.value === 1) {
        //政务版
        isShow.value = true
        state.columns = apprpvedColumn
      } else {
        //企业版
        isShow.value = false
        state.columns = apprpvedColumns
      }
    }
    onMounted(() => {
      getDetailsType()
      if (route.query.documentId) state.documentId = route.query.documentId
      getList()
      setTimeout(() => {
        thirdparty()
      }, 500)
    })

    return {
      isShow,
      addApply,
      ...toRefs(state),
      searchList,
      handleTableChange,
      openModal,
      sealStatus,
      examineStatusObj,
      approvalStatusList,
      tableRef,
      clickFocus,
      inputbox,
      headFormState,
      sealTypeList,
      headFormRef,
      reset,
      detailType
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .ant-select {
  .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
  }
  .ant-select-selection-placeholder {
    line-height: 36px;
  }
  .ant-select-selection-item {
    line-height: 36px;
  }
}
::v-deep .ant-mentions {
  height: 88px !important;
}
.dotted {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
}
.seal-list {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
</style>
