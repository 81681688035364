<!--  -->
<template>
  <div class="image-item" :style="getItemMargin(imageData.type)">
    <div class="img-box">
      <img :src="dealImageUrl(imageData)" alt="" />
      <i
        v-if="getShow(imageData)"
        :style="{
          display: 'block',
          position: 'absolute',
          top: '0',
          right: '0',
          width: '33px',
          height: '30px',
          background: `url(${iconList[imageData.imageType - 1]}) right top no-repeat`,
          backgroundSize: 'auto 100%'
        }"
      ></i>
      <div v-if="imageData.deviceType" class="name-box">
        <!-- 用印影像展示拍摄的设备 -->
        <Tooltip :value="sourceType[imageData.deviceType - 1]" />
      </div>
    </div>

    <!-- 文字部分 -->
    <div v-if="imageData.type <= 1">
      <!-- 用印影像区分老数据新数据 fileSource 不为空展示-->
      <p class="staff-name">{{ imageData.staffName }}</p>
      <p v-if="imageData.time" class="time">{{ imageData.time }}</p>
      <p v-if="imageData.address">{{ imageData.address }}</p>
    </div>
    <p v-else class="staff-name">{{ imageData.staffName }}</p>
    <!-- 临时占位用的，别删 -->
    <p></p>

  </div>
</template>

<script setup>
import Tooltip from '../../tooltip.vue'
import { defineProps, computed, reactive, toRefs, onBeforeMount, onMounted } from 'vue'
const bugai = require('@/assets/images/seal-record/cover.png')
const bupai = require('@/assets/images/icon.png')
const beforeSeal = require('@/assets/images/seal-record/before_seal.png')
const afterSeal = require('@/assets/images/seal-record/after_seal.png')

const props = defineProps({
  imageData: {
    type: Object,
    default: () => ({})
  }
})

const iconList = reactive(['', bupai, bugai, '', afterSeal])

//来源
const sourceType = reactive(['天玺', '金盾', '手机', '印控仪', 'APP', '小程序', 'H5'])

// 判断图片地址
const dealImageUrl = computed(() => data => {
  console.log('判断图片地址', data)
  if (data.type === 1) {
    //type === 1 documentFileRelVO字段下内容
    if (data.deviceType === 2) {
      //简易款显示无影像
      return require('@/assets/images/no-image.png')
    } else {
      if (data.pictureType <= 0) {
        //photoType是否抓拍成功 1、影像抓拍成功 2、抓拍失败
        if (data.photoType > 1) {
          return require('@/assets/images/grab-failure.png')
        }
        if (!data.fullFile) return require('@/assets/images/video-synchronization.png')
        return data.fullFile
      }
      //影像关闭
      return require('@/assets/images/no-video.png')
    }
  }
  return data.fullFile
})
// 名称是否展示
const showName = computed(() => imgData => {
  return imgData.type === 1 || imgData.type === 4 || imgData.type === 0
})
// 获取间距
const getItemMargin = computed(() => type => {
  // 此处type来源可到utils文件中 dealImageData 函数中找到
  if (type === 4) return { marginRight: '16px' }
  return { marginRight: '24px' }
})
const getShow = computed(() => data => {
  console.log('imageDataimageDataimageData',data);
  if (!data.imageType) return false
  // item.imageType === 2 || (item.imageType === 3 && isShowCoverInfo) 原判断条件 不知道这事个啥
  if ([2, 3, 4, 5].includes(data.imageType)) return true
  return false
})
</script>
<style scoped lang="less">
.image-item {
  text-align: center;
  margin-bottom: 10px;
  .img-box {
    width: 104px;
    display: inline-block;
    position: relative;
    img {
      width: 104px;
      height: 104px;
      object-fit: fill;
    }
    .name-box {
      position: absolute;
      bottom: 0;
      // left: 11px;
      left: 0;
      height: 26px;
      width: 104px;
      text-align: center;
      line-height: 26px;
      background: rgba(26, 26, 27, 0.6);
      :deep(.tooltip-box) {
        color: white;
        border: none;
        padding: 0;
        margin: 0 10px;
      }
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
    width: 126px;
  }
  .staff-name {
    margin: 4px auto 0;
    width: 84px;
    white-space: nowrap; // 不换行
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .time {
    margin-bottom: 4px;
    white-space: nowrap;
    font-size: 14px;
  }
}
</style>
