<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-28 11:20:25
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-06 10:50:55
 * @FilePath: \yda_web_manage\src\components\detailForm\components\tooltip.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="tooltip-box">
    <a-tooltip v-if="value && value.length > 6" :arrowPointAtCenter="true">
      <template #title>{{ value }}</template>
      {{ value }}
    </a-tooltip>
    <span v-else>{{ value || '' }}</span>
  </div>
</template>

<script setup>
import { reactive, defineProps, toRefs, onBeforeMount, onMounted } from 'vue'
const props = defineProps({
  value: {
    type: String,
    default: ''
  }
})
</script>
<style scoped lang="less">
.tooltip-box {
  border: 1px solid rgba(220, 222, 224, 1);
  border-radius: 1.5px;
  max-width: 94px;
  height: 22px;
  line-height: 22px;
  padding: 0 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 4px;
}
</style>
