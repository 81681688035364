<!--  扫描仪v1.0 -->
<template>
  <div class="file-check-ocr" v-show="visible">
    <div class="header">
      <span class="close" @click="close">返回</span>
      <span class="head-name">{{ applyFileName }}</span>
    </div>
    <a-spin tip="加载中..." :spinning="spinning">
    <div class="middle">
      <div class="mid-left" v-if="allFileList.length > 1">
        <div class="mid-left-con">
        <div
          :class="[state.selectFile === fileIdx ? 'mid-left-item-sel' : '', 'mid-left-item']"
          v-for="(i, fileIdx) in allFileList"
          :key="fileIdx"
          @click="handleFile(fileIdx)"
        >
          <div class="file-content">
            <img class="file-img" :src="getImgUrl(i)" alt="" />
            <div class="file-operate">
              <p class="file-name" v-if="i.name.length > 14">{{ i.name.slice(0, 6) + '...' }}</p>
              <p class="file-name" v-else>{{ i.name }}</p>
              <p class="file-ope" v-if="i.operationList[0].operationName.length > 6">操作人：{{ i.operationList[0].operationName.slice(0, 6) + '...' }}</p>
              <p class="file-ope" v-else>操作人：{{ i.operationList[0].operationName }}</p>
            </div>
          </div>
          <div v-if="i.pageNumber" class="file-page">{{  i.pageNumber }}页</div>
        </div>

        </div>
      </div>
      <div class="mid-right">
        <p class="file-name name-t" v-if="allFileList[state.selectFile]?.name">
          {{ allFileList[state.selectFile].name  + '.' + allFileList[state.selectFile].suffix }}
        </p>
        <div class="file-container">
          <div class="file-list">
            <div
              :class="[state.selectImg === ocrIdx ? 'file-list-item-sel' : '', 'file-list-item']"
              v-for="(item, ocrIdx) in allFileList[state.selectFile]?.ocrFileVOList"
              :key="ocrIdx"
              @click="handleImg(ocrIdx)"
            >
              <img class="file-compare-img" :src="item.fullFile" alt="" />
              <div :class="[item.meetType === 2?'file-ocr-rate-fail':'file-ocr-rate-suc', 'file-ocr-rate']">{{item.blankPage?'空白页':'匹配率'+item.chance+'%'}}</div>
            </div>
          </div>
          <!-- <div class="diff-box" ref="comparisonDom">
            <div
              class="view-content origin"
              @mousemove="onMousemove"
              @mousedown="onMousedown"
              data-key="parent"
              :style="{ top: state.imgY + 'px', left: state.imgX + 'px', transform: `scale(${state.scale})` }"
            >
              <img ref="imgDom" :src="allFileList[state.selectFile]?.ocrFileVOList[state.selectImg].fullFile" alt="" />
            </div>
          </div> -->
          <div class="diff-box preview-img" ref="comparisonDom">
              <div
                class="preview view-content origin"
                @mousewheel.prevent="rollImg($event)"
              >
                <img
                  v-if="allFileList[state.selectFile]?.ocrFileVOList[state.selectImg].fullFile"
                  class="img"
                  ref="imgDom"
                  :src="allFileList[state.selectFile]?.ocrFileVOList[state.selectImg].fullFile"
                  alt=""
                  @mousedown="move"
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, defineProps, defineEmits, defineExpose, watch, nextTick, computed } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  allFileList: {
    //附件列表
    type: Array,
    default: () => []
  },
  applyFileName: { // 申请流程主题
    type: String,
    default: ''
  },
  selectFileIndex: { // 查看附件索引
    type: Number,
    default: 0
  }
  // ocrFileVOList: { // ocr识别图片列表
  //   type: Array,
  //   default: () => []
  // }
})

const emit = defineEmits(['update:visible'])

const state = reactive({
  imgY: '',
  imgX: '',
  innerY: 0,
  innerX: 0,
  tipX: 0,
  tipY: 0,
  isDown: false,
  scale: 1,
  tipContent: '',
  tipShow: false,
  page: {
    total: 10,
    curentIndx: 1
  },
  current: {},
  zoomVal: 1,
  currentImg: 0,

  fileList: [
    // { name: '市场部文件.pdf', id: '1' },
    // { name: '市场部文件.word', id: '2' },
    // { name: '市场部文件.txt', id: '3' }
  ],
  selectFile: 0,
  ocrFileList: [
    // { img: require('@/assets/images/seal-record/ocr-file-pdf.png') },
    // { img: require('@/assets/images/seal-record/ocr-file-pdf.png') },
    // { img: require('@/assets/images/seal-record/ocr-file-pdf.png') },
    // { img: require('@/assets/images/seal-record/ocr-file-pdf.png') }
  ],
  selectImg: 0
})

const spinning = ref(false)

const getImgUrl = computed(() => i => {
  const obj = {
    docx: require('@/assets/images/word.png'),
    doc: require('@/assets/images/word.png'),
    xls: require('@/assets/images/excel.png'),
    xlsx: require('@/assets/images/excel.png'),
    pdf: require('@/assets/images/pdf.png'),
    text: require('@/assets/images/txt.png')
  }
  return obj[i.suffix] ? obj[i.suffix] : i.fullFile
})

const rectConfig = reactive({
  className: ['add', 'del', 'mod'],
  tipTitle: ['增加：', '删除：', '修改：']
})
//tip的父级容器
const comparisonDom = ref(null)
//对比文件dom
const imgDom = ref(null)

//阻止默认事件
const pauseEvent = e => {
  if (e.stopPropagation) e.stopPropagation()
  if (e.preventDefault) e.preventDefault()
  e.cancelBubble = true
  e.returnValue = false
  return false
}

//鼠标移动
const onMousemove = e => {
  if (!state.isDown) return

  state.imgX = e.clientX - state.innerX
  state.imgY = e.clientY - state.innerY
}

//鼠标按下
const onMousedown = e => {
  state.isDown = true
  //阻止冒泡与默认事件
  pauseEvent(e)
  state.innerY = e.clientY - e.currentTarget.offsetTop
  state.innerX = e.clientX - e.currentTarget.offsetLeft
}

//鼠标按下抬起
const onMouseUp = () => {
  console.log('up')
  state.isDown = false
}
// 缩放图片
const rollImg = (event) => {
      console.log("eve", event);
      /* 获取当前页面的缩放比 若未设置zoom缩放比，则为默认100，原图大小 */
      let zoom = (state.zoomVal += event.wheelDelta / 1200);
      console.log("imgDom", imgDom, zoom, event);
      if (state.zoomVal >= 0.2) {
        imgDom.value.style.transform =
          "scale(" + state.zoomVal + ")";
      } else {
        state.zoomVal = 0.2;
        imgDom.value.style.transform =
          "scale(" + state.zoomVal + ")";
        return false;
      }
      /*根据修改transform 改变图片缩放大小 */
      // imgDom.value[0].style.transform = "scale(" + zoom / 100 + ")";
      // state.zoomVal = zoom;
    };

const move = (e) => {
      console.log("e-move", e);
      e.preventDefault();
      // 获取元素
      var preview = document.querySelectorAll(".preview");
      var img = document.querySelectorAll(".img");
      console.log("dom", preview);
      var x = e.pageX - img[state.currentImg].offsetLeft;
      var y = e.pageY - img[state.currentImg].offsetTop;

      // 添加鼠标移动事件
      preview[state.currentImg].addEventListener("mousemove", move);
      function move(e) {
        img[state.currentImg].style.left = e.pageX - x + "px";
        img[state.currentImg].style.top = e.pageY - y + "px";
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img[state.currentImg].addEventListener("mouseup", function () {
        preview[state.currentImg].removeEventListener("mousemove", move);
      });
      // 鼠标离开父级元素，把事件移除
      preview[state.currentImg].addEventListener("mouseout", function () {
        preview[state.currentImg].removeEventListener("mousemove", move);
      });
  };

//弹窗关闭
const close = () => {
  rest()
  emit('update:visible', false)
}
const handleFile = index => {
  state.selectFile = index
  state.selectImg = 0
  state.ocrFileList = props.allFileList[index].ocrFileVOList
}
const handleImg = index => {
  state.selectImg = index
}

//重置所有偏移量坐标
const rest = () => {
  state.imgY = ''
  state.imgX = ''
  state.innerY = 0
  state.innerX = 0
  state.tipX = 0
  state.tipY = 0
  state.isDown = false
  state.scale = 1
  state.tipContent = ''
  state.tipShow = false
}

const domInit = type => {
  //初始化当页数
  state.current = props.allFileList[0]?.ocrFileVOList[state.page.curentIndx - 1]
  //重置坐标
  rest()
  //图片相对于原始尺寸的比例
  // let radio = 505 / imgDom.value.naturalWidth
  //创建色块
  if (type == 'mounted') return
  // const elements = state.current.ocrFileContent.map(item => {
  //   const element = createNode(item, radio)
  //   return element
  // })
}

const pageInit = (curentIndx = 1, type) => {
  nextTick(() => {
    state.page.total = props.allFileList[0]?.ocrFileVOList.length
    state.page.curentIndx = curentIndx
    state.selectFile = props.selectFileIndex
    domInit(type)
    spinning.value = false
  })
}
watch(
  () => props.visible, val => {
    spinning.value = true
  })

//这个得调一下，确保第一次拿到的图片naturalWidth正确（具体啥原因还待确认）
onMounted(() => {
  console.log('allFileList', props.allFileList)
  pageInit(1, 'mounted')
})

defineExpose({
  pageInit
})
</script>
<style lang="less" scoped>
.file-check-ocr {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  //   padding-bottom: 76px;
  //   padding-top: 72px;
  background: #f0f2f5;
  .header {
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    .close {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      width: 65px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 32px;
      text-align: center;
      display: inline-block;
      margin: 0 24px 0 40px;
      cursor: pointer;
    }
    .head-name {
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }
  }
  .middle {
    display: flex;
    justify-content: center;
    padding: 0 24px;
    margin-top: 84px;
    .file-name {
      font-weight: 500;
      font-size: 16px;
      color: #333333;
    }
    .mid-left {
      width: 30%;
      height: calc(100vh - 107px);
      background: #ffffff;
      overflow: hidden;
      .mid-left-con {
      // width: 30%;
      padding: 24px;
      height: calc(100vh - 107px);
        overflow-y: scroll;
      }
      .mid-left-item-sel {
        box-shadow: 0px 10px 14px 0px rgba(240, 65, 54, 0.1);
        border: 1px solid #f04136 !important;
      }
      .mid-left-item {
        height: 78px;
        background: #ffffff;
        border: 1px solid #ececec;
        padding: 0 16px;
        margin-bottom: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .file-content {
          display: flex;
          align-items: center;
        }
        .file-operate {
          margin-left: 16px;
          line-height: 24px;
        }
        .file-img {
          width: 40px;
          height: 40px;
        }
        .file-ope {
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
        .file-page {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .mid-right {
      width: 70%;
      margin-left: 24px;
      .name-t {
        text-align: center;
        margin-bottom: 17px;
      }
      .file-container {
        // width: 968px;
        width: 100%;
        height: calc(100vh - 139px);
        background-image: url('../../../assets/images/seal-record/ocr-check-bg.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #fff;
        padding: 16px 0;
        display: flex;
        .file-list::-webkit-scrollbar {
          width: 6px;
          border-radius: 3px;
          display: inline-block;
        }
        .file-list::-webkit-scrollbar-thumb {
          background-color: #e7e7e7;
        }
        .file-list {
          width: 140px;
          height: calc(100vh - 174px);
          border-right: 1px solid #f4f4f4;
          overflow-y: scroll;
          .file-list-item-sel {
            box-shadow: 0px 0px 20px 0px rgba(240, 65, 54, 0.4);
            border: 1px solid #f04136 !important;
          }
          .file-list-item {
            width: 96px;
            height: 96px;
            border: 1px solid #e8e8e8;
            position: relative;
            margin: 0 auto 10px;
            cursor: pointer;
            .file-compare-img {
              width: 100%;
              height: 100%;
            }
            .file-ocr-rate {
              width: 100%;
              height: 20px;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              position: absolute;
              z-index: 9;
              bottom: 0;
            }
            .file-ocr-rate-suc {
              background-color: rgba(0, 0, 0, .4);
              color: #ffffff;
            }
            .file-ocr-rate-fail {
              background-color: rgba(210, 56, 56, .6);
              color: #FFFFFF;
            }
          }
        }
        .diff-box {
          width: calc(100% - 196px);
          height: 100%;
          overflow: hidden;
          position: relative;
          background: #f4f4f4;
          margin-left: 32px;
          display: flex;
          justify-content: center;
        }
        .view-content {
          width: 505px;
          position: absolute;
        }
        .origin {
          img {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.preview-img {
  position: relative;
  .preview {
    width: 100%;
    // height: 560px;
    height: 100%;
    transition: height linear 3s;
    // position: relative;
    // overflow: hidden;
    .img {
      width: 100%;
      height: 100%;
      // max-width: 923px;
      // max-height: 460px;
      // border-radius: 12px;
      transition: height linear 3s;
      cursor: move;
      position: relative;
    }
  }
}
</style>