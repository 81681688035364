// 严凯 V2.2.0 /* * @Descripttion: 用印申请详情 * @version: 2.2.1 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-03-25
14:56 * @version: 2.2.2 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-04-02 15:10 @version: 2.5.2 * @LastEditors: 刘志坤
* @LastEditTime: 2022-07-21*/
<template>
  <a-spin :spinning="loading">
    <section class="seal-detail">
      <a-card class="seal-title">
        <!-- line1 -->
        <a-row style="margin-bottom: 8px" justify="space-between">
          <a-col class="seal-headline" :span="18">
            <a-row>
              <!-- 流程主题 -->
              <!-- :title="current.fileName" -->
              <div class="info-block">
                <span class="info-title">流程主题：</span>
                <a-tooltip
                  :title="current.fileName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.fileName && current.fileName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.fileName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.fileName ?? '--' }}</span>
              </div>
              <!-- 行政区域 -->
              <div class="info-block" v-if="detailType == 1">
                <span class="info-title">行政区域：</span>
                <a-tooltip
                  :title="current.departmentName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.departmentName && current.departmentName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.departmentName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.departmentName ?? '--' }}</span>
              </div>
              <!-- 部门 -->
              <div class="info-block" v-else>
                <span class="info-title"><span style="letter-spacing: 2em;">部</span>门：</span>
                <a-tooltip
                  :title="current.departmentName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.departmentName && current.departmentName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.departmentName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.departmentName ?? '--' }}</span>
              </div>
            </a-row>
          </a-col>
          <!-- 申请状态/用印状态标题 -->
          <a-col :span="6">
            <a-row justify="end" style="text-align: end">
              <a-col :span="12"
                ><span class="status">{{ detailType === 2 ? '申请状态' : '审批状态' }}</span></a-col
              >
              <a-col :span="12" v-if="detailType == 2"><span class="status">用印状态</span></a-col>
            </a-row>
          </a-col>
        </a-row>
        <!-- line2 -->
        <a-row justify="space-between">
          <a-col class="seal-headline" :span="18">
            <a-row>
              <!-- 申请人 -->
              <div class="info-block">
                <div class="info-title applicant">
                  <div class="special"><span>申</span><span>请</span><span>人</span></div>
                  ：
                </div>
                <a-tooltip
                  :title="current.staffName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.staffName && current.staffName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.staffName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.staffName ?? '--' }}</span>
              </div>
              <!-- 预计用印日期 -->
              <div class="info-block seal-date">
                <span class="info-title">预计用印日期：</span>
                <span class="info-content">{{ current.sealTime ?? '--' }}</span>
              </div>
              <!-- 盖印方式 -->
              <div class="info-block seal-way" v-if="detailType == 2">
                <span class="info-title">盖印方式：</span>
                <span class="info-content" v-if="current.remote === 1">常规盖印</span>
                <span class="info-content" v-if="current.remote === 2">远程盖印</span>
                <span class="info-content" v-if="current.remote === 3">连续盖印</span>
              </div>
            </a-row>
          </a-col>
          <a-col :span="6">
            <a-row justify="end" style="text-align: end">
              <!-- 审批状态 -->
              <a-col :span="12">
                <p class="seal-status">
                  <span
                    class="dotted"
                    :style="{ background: examineStatusObj[current.examineStatus ?? 0].color }"
                  ></span
                  >{{ examineStatusObj[current.examineStatus ?? 0].name }}
                </p>
              </a-col>
              <!-- 用印状态 -->
              <a-col :span="12" v-if="detailType == 2">
                <p class="seal-status">
                  <span class="dotted" :style="{ background: sealStatusObj[current.sealStatus ?? 0].color }"></span
                  >{{ sealStatusObj[current.sealStatus ?? 0].name }}
                </p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
      <DetailForm />
      <!-- tab切换 -->
      <!-- <div class="content-box-tab" v-show="!isFillCover">
        <a-tabs :tabBarStyle="tabBarStyle">
          <a-tab-pane key="1" tab="申请信息">
            <div id="tab-info"></div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="补盖信息">
            <div class="fill-box seal-tabs">
              <a-card style="margin-bottom: 16px" v-for="(item, index) in sealDataList('fillCover')" :key="index">
                <template #title>
                  <div>
                    <p class="title">{{ item.sealName }}</p>
                    <a-row class="firs-line">
                      <a-col :span="6">
                        <span class="label">申请补盖次数：</span>
                        <span class="value"> {{ item.reApplicationNumber }} 次 </span>
                      </a-col>
                      <a-col :span="6" class="spill-box">
                        <span class="label">盖印人：</span>
                        <section class="value spill">
                          <SealStaff
                            :list="item?.sealCoverStaffRelVOList"
                            v-if="item?.sealCoverStaffRelVOList && item.sealCoverStaffRelVOList.length"
                          />
                          <span v-else>无</span>
                        </section>
                      </a-col>
                      <a-col :span="6" class="spill-box">
                        <span class="label">申请人：</span>
                        <span class="value spill">{{ item.affixedStaffName }}</span>
                      </a-col>
                      <a-col :span="6" class="spill-box">
                        <span class="label">补盖原因：</span>
                        <span class="value spill">{{ item.reReason === 5 ? item.reText : item.reReasonName }}</span>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="24">
                        <div style="display: flex;">
                          <p class="label">附件：</p>
                          <p v-if="item.fileList === undefined || item.fileList.length <= 0" style="font-size: 14px;">
                            无
                          </p>
                          <div v-viewer="otherOptions" :images="current.imgList">
                            <div class="applyFileBox" v-for="(it, index) in item.fileList" :key="it.fileId">
                              <div class="seal-imgs" style="position: relative;">
                                <a v-if="fileTypeObj[it.suffix]">
                                  <img :src="getImgUrl(it.suffix)" class="seal-img" alt="" />
                                </a>
                                <div
                                  class="downloadFileBox"
                                  @click="downloadFile(it.fullFile)"
                                  v-if="fileTypeObj[it.suffix]"
                                >
                                  <i class="iconfont icon-xiazai icons" style="color:white;font-size:25px;"></i>
                                </div>
                                <img
                                  v-else
                                  :src="it.fullFile"
                                  alt=""
                                  @error="reImg($event, it.fullFile)"
                                  @click="previewImg(it.fullFile, index, 'applyFile')"
                                  class="seal-img"
                                />
                              </div>
                              <div style="flex:1" :title="it.fileName" class="single-wrap fill-cover">
                                {{ it.fileName ?? '无' }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </template>
                <approval-time-line-list :approvalList="item.processList || []" :isFillCover="isFillCover" />
              </a-card>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div> -->
    </section>
  </a-spin>
  <!-- <photo-view :photoList="current.imgList"
                  v-model:visible="previewVisible"
                  :photoIndex="photoIndex" :reverseImg="reverseImg"></photo-view> -->
  <!-- <section class="add-foot" v-if="(current.ableCancel || current.ableReSubmit) && detailType === 2">
    <a-button @click.prevent="handleCancel" class="cancel-btn">取消</a-button>
    <a-button @click="openModal()" :loading="loading" class="submit-btn" v-if="current.ableCancel">撤销申请</a-button>
    <a-button
      @click="$router.push(`/seal/apply/add?documentId=${$route.query.documentId}&status=2`)"
      :loading="loading"
      class="submit-btn"
      v-if="current.ableReSubmit"
      >重新提交</a-button
    >
  </section> -->

  <revoke-modal v-model:visible="revokeVisible" title="撤销申请" @bindSubmit="bindSubmit"></revoke-modal>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, createVNode, computed } from 'vue'
import { getApplyDetail, getFingerprintDetail, backoutSeal, revocationReason } from '@/apis/seal'
import { RightOutlined } from '@ant-design/icons-vue'
import PhotoView from '@/components/PhotoView'
import '@/style/jsx-label-text.scss'
import { useRoute, useRouter } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import { getDetailsApplyType } from '@/apis/user'
import ApprovalTimeLineList from '@/views/components/approvalTimeLineList'
import { approvalStatusObj, archiveStatusObj, examineStatusObj, sealStatusObj, fileTypeObj } from '@/utils/constData'
//用印视频
import VideoList from '@/components/VideoList'
//弹窗
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import DetailForm from '@/components/detailForm/index'

import revokeModal from './components/revokeModal.vue'
import SealStaff from '@/components/sealStaff/index'
const typesObj = {
  1: {
    name: '单次用印',
    color: '#C3161C'
  },
  2: {
    name: '连续用印',
    color: '#5F99FF'
  }
}

const printImgIcon = [
  require('@/assets/images/noPrint.png'),
  require('@/assets/images/tobePrint.png'),
  require('@/assets/images/print.png')
]

export default defineComponent({
  components: {
    DetailForm,
    // 'label-text': LabelText,
    ApprovalTimeLineList,
    // PhotoView,
    VideoList,
    RightOutlined,
    revokeModal,
    SealStaff
  },
  setup() {
    // tabbar样式
    const tabBarStyle = {
      background: 'white'
    }
    //路由
    const router = useRouter()
    //用印视频
    const iconUrl = 'assets/images/playIcon2.png'

    const route = useRoute()
    const current = ref({ documentFileIds: [], sealFlowShow: [], ordinaryFiles: [] }) // 详情数据
    const loading = ref(true)
    const previewVisible = ref(false)
    const reverseImg = ref(false)
    const photoIndex = ref(0)
    const isVirtualListScroll = ref(0)
    // 是不是补盖数据 false为补盖数据
    const isFillCover = computed(() => {
      if (current.value.hasOwnProperty('sealShow')) {
        return current.value?.flowType === 1
      }
      return true
    })
    const state = reactive({
      list: [],
      revokeVisible: false,
      revokeDocumentId: null // 当前文件id
    })

    // 印章数据
    const sealDataList = computed(() => type => {
      if (!!current.value.sealShow) {
        return type === 'fillCover'
          ? current.value.sealShow.filter(item => item.reExamine !== null)
          : current.value.sealShow
      }
      return []
    })

    const getImgUrl = src => {
      return require('@/assets/images/' + fileTypeObj[src])
    }
    const getDetail = () => {
      // if (route.query.documentId || route.query.fingerId) {
      //   return getFingerprintDetail({ documentId: route.query.documentId || route.query.fingerId })
      // }
      return getApplyDetail(route.query.documentId)
    }

    //确认撤销
    const bindSubmit = async params => {
      console.log(params, '撤销原因')
      const res = await revocationReason({
        withdrawCause: params.revoke,
        id: state.revokeDocumentId
      })
      if (res.success) {
        cmsNotice('success', '您的申请已撤销成功！')
        getList()
      } else if (res.code === 1068) {
        // cmsNotice('error', '用印申请已撤销！')
        getList()
      }
    }

    //获取数据
    const getList = async () => {
      const res = await getDetail()
      current.value = res.data
      current.value.ordinaryFiles = []

      // 所有补盖附件数组
      let fillCoverFiles = []
      if (current.value.documentApplyFileS !== null) {
        // 普通附件数组  status 为附件是否为补盖文件 1 为非补盖附件 2 补盖文件
        current.value.ordinaryFiles = current.value.documentApplyFileS.filter(item => {
          return item.status === 1
        })
        fillCoverFiles = current.value.documentApplyFileS.filter(item => {
          return item.status === 2
        })
      }
      disposeFillSealData(fillCoverFiles, current.value.sealFlowCoverShow)

      console.log('current的值', current.value)
      current.value.sealShow &&
        current.value.sealShow.forEach((item, index) => {
          if (item.status == 0) {
            item.icon = printImgIcon[0]
          } else if (item.status == 1) {
            item.icon = printImgIcon[1]
          } else {
            item.icon = printImgIcon[2]
          }
          let arr = []
          res.data.sealFlowShow.forEach(itemChild => {
            if (item.sealId === itemChild.sealId) {
              arr.push(itemChild)
              item.isRecordBtn = itemChild.finishType === 2
            }
          })
          state.list[index] = arr
        })
      console.log('---3----', current.value.sealShow)
      current.value.useCount = route.query.fingerId
        ? 1
        : res.data.sealShow.reduce((acct, item) => acct + item.practicalNumber, 0)
      loading.value = false
      isVirtualListScroll.value++
    }
    onMounted(() => {
      getDetailsType()
      getList()
    })

    // 处理补盖附件、流程与印章的数据
    const disposeFillSealData = (files, process) => {
      // 处理附件和印章对应关系
      if (files.length > 0) {
        current.value.sealShow.forEach(item => {
          item['fileList'] =
            files.filter(val => {
              return val.sealId === item.sealId
            }) || []
        })
        console.log('************1', current.value.sealShow)
      }
      // 处理流程和印章对应关系
      if (process.length > 0) {
        current.value.sealShow.forEach(item => {
          item['processList'] =
            process.filter(val => {
              return val.sealId === item.sealId
            }) || []
        })
      }
    }

    const previewImg = (src, i, type) => {
      if (!src) return
      reverseImg.value = false
      current.value.imgList = []
      if (type == 'printImg') {
        current.value.documentFileRelVO?.map(item => {
          if (item.fullFile) current.value.imgList.push(item.fullFile)
        })
        reverseImg.value = true
      } else if (type == 'applyFile') {
        current.value.documentApplyFileS?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            current.value.imgList.push(item.fullFile)
          }
        })
      } else if (type == 'remoteFile') {
        // current.value.imgList = [].concat(current.value.documentApplyFileS)
        current.value.documentRemoteVice?.map(item => {
          current.value.imgList.push(item.fullFile)
        })
      }
      photoIndex.value = current.value.imgList.indexOf(src)
      previewVisible.value = true
    }
    const reImg = (e, src, sign) => {
      console.log('12312312')
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      if (sign === 'a') {
        const tipsText = document.createTextNode('点击下 载该附件查看')
        tipsSpan.appendChild(tipsText)
        tipsSpan.addEventListener('click', () => {
          window.location.href = src.replace('.com//', '.com/') + '?upload=true'

          setTimeout(() => {
            cmsNotice('success', '正在为你下载，请耐心等待')
          }, 200)
        })
        parentNode.removeChild(e.target)
        parentNode.appendChild(tipsSpan)
      } else {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '104px'
        tipsDiv.style.width = '104px'
        tipsDiv.style.background = '#F2F2F2'
        tipsDiv.style.textAlign = 'center'
        // tipsDiv.style.transform = 'rotateZ(180deg)'
        tipsDiv.style.color = '#B1B1B1'
        tipsDiv.style.padding = '40px 0 0 0'
        const tipsI = document.createElement('i')
        const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
        tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
        tipsI.style.fontSize = '30px'
        tipsI.style.color = '#D9D9D9'
        tipsI.style.marginBottom = '10px'
        tipsI.style.height = '25px'
        tipsI.style.display = 'block'
        tipsDiv.appendChild(tipsI)
        tipsDiv.appendChild(tipsText)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
      }

      // parentNode.removeChild(e.target)
      // parentNode.appendChild(tipsSpan)
    }

    // 是否为企业版 列表数据不同
    const detailType = ref()
    const getDetailsType = async () => {
      const res = await getDetailsApplyType()
      detailType.value = res.data
      console.log(detailType.value)
    }

    const downloadFile = src => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'
      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    }
    //取消
    const handleCancel = () => {
      router.back()
    }
    //撤销申请
    const openModal = () => {
      console.log('currentId', route.query.documentId)
      state.revokeDocumentId = route.query.documentId
      // Modal.confirm({
      //   title: '是否撤销！',
      //   content: '请确认是否执行【撤销申请】操作?',
      //   centered: true,
      //   confirmLoading: state.loading,
      //   icon: createVNode(ExclamationOutlined),
      //   onOk: async () => {
      //     const res = await backoutSeal({ id: route.query.documentId })
      //     if (res.success) {
      //       cmsNotice('success', '您的申请已撤销成功！')
      //       getList()
      //     } else if (res.code === 1068) {
      //       // cmsNotice('error', '用印申请已撤销！')
      //       router.push('/seal/apply')
      //     }
      //   }
      // })
      state.revokeVisible = true
    }
    console.log(current, '666666666')
    return {
      sealDataList,
      isFillCover,
      tabBarStyle,
      iconUrl,
      getDetailsType,
      downloadFile,
      getImgUrl,
      fileTypeObj,
      current,
      loading,
      ...toRefs(state),
      approvalStatusObj,
      examineStatusObj,
      sealStatusObj,
      archiveStatusObj,
      typesObj,
      previewVisible,
      reverseImg,
      previewImg,
      reImg,
      detailType,
      isVirtualListScroll,
      photoIndex,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      },
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      handleCancel,
      openModal,
      getList,
      bindSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
// :deep(.ant-spin-container) {
//   margin-top: -24px;
// }
.seal-detail {
  margin-top: -36px;

  .label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    vertical-align: top;
  }

  .value {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    display: inline-block;
    flex: 0.9;
  }

  .value-wrap {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap !important;
    vertical-align: top;
    width: 70%;
  }

  .value-omit {
    width: 70%;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .seal-title {
    border: none;

    :deep(.ant-card-body) {
      padding: 4px 24px 16px;
    }

    .seal-status {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
    }
  }

  .dotted {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    transform: translateY(-2px);
  }

  .no-record {
    width: 300px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 10% auto;

    > img {
      width: 300px;
      height: 266px;
      margin-bottom: 8px;
    }
  }

  // -----分割线------
  .content-box-tab {
    /deep/.ant-tabs-nav-container {
      padding: 15px 24px 0 24px;
      border-top: 1px solid #e9e9e9;

      .ant-tabs-tab {
        padding: 0;
        padding-bottom: 11px;
        margin-right: 50px;
      }
    }
  }

  // -----分割线------
}

// ---分割线----
.fill-box {
  /deep/.ant-card-head {
    border-bottom: 1px solid #e9e9e9;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
  }

  .firs-line {
    margin: 8px 0;
  }

  .label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 22px;
    vertical-align: initial;
  }

  .value {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 22px;
  }

  .seal-imgs {
    margin-right: 12px !important;
    margin-bottom: 8px !important;

    .seal-img {
      width: 44px !important;
      height: 44px !important;
      border: 1px solid #dbdbdb;
      border-radius: 2px;
    }

    .downloadFileBox {
      height: 15.02px !important;
      line-height: 15.02px !important;

      i {
        font-size: 15px !important;
      }
    }
  }
}

// ---分割线----

.seal-tabs {
  padding: 16px 24px 0;

  .spill-box {
    display: flex;

    .spill {
      white-space: break-spaces;
    }
  }

  .applyFileBox {
    display: flex;
    align-items: center;

    &:hover .downloadFileBox {
      display: block;
    }

    .downloadFileBox {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      height: 35px;
      line-height: 35px;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .stamped-mage {
    width: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 26px;
    margin-bottom: 10px;
  }

  .seal-imgs {
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // padding: 8px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 0;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    .seal-img {
      width: 108px;
      height: 108px;
    }
  }

  .seal-no-img {
    width: 104px;
    height: 104px;
  }

  .process {
    :deep(.ant-card-head-wrapper) {
      align-items: flex-start;
    }

    .card-title {
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      .card-title-item {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(0, 0, 0, 0.65);
      }

      .card-title-items {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .tab-btn {
      width: 80px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #0A7BFF;

      :deep(.anticon) {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.show-content {
  .show-img-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .show-img-div {
      width: 104px;
      height: 104px;
      margin-right: 16px;
      margin-bottom: 8px;

      .show-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

:deep(div.image-list-module) {
  //视频
  .video-box {
    position: relative;
    width: 104px;
    height: 104px;
    border: none;
    padding: 0;
    margin-right: 16px;
    margin-bottom: 8px;

    .min-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .play-image {
      width: 19px;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}

// 定位
.seal-headline {
  // position: relative;
  .seal-item {
    // position: absolute;
    // top: 0;
    // left: 19.125rem;
  }
}

.single-wrap {
  width: 10em;
}
.fill-cover {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

//取消/撤销申请
.add-foot {
  width: calc(100vw - 208px);
  padding: 12px 24px;
  text-align: right;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

  .cancel-btn,
  .cancel-btn:hover,
  .cancel-btn:focus,
  .cancel-btn:active,
  .cancel-btn.active {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;
    border-radius: 2px;
  }

  .submit-btn {
    background-color: #c3161c;
    border-radius: 2px;
    border: none;
    color: #ffffff;
  }
}
</style>

<style lang="less" scoped>
.info-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 27px;

  .info-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  .applicant {
    display: flex;
    flex-direction: row;
    align-items: center;

    .special {
      width: 4em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .info-content {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  .content-wrap {
    width: 13em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}

.seal-date {
  min-width: 155px;
}

.seal-way {
  margin-left: 101px;
}

.status {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
</style>
<style lang="less">
//提示
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }

  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>
