<template>
  <div class="ocr-modal" v-show="visible">
    <div class="modal-content">
      <div class="head">
        <div class="head-content">
          <div class="title">
            审核人：
            {{ state.current.remoteStaffVo ? state.current.remoteStaffVo?.staffName : '系统自动通过' }}
            <!-- <span class="examine-type" v-else>(系统自动通过）</span> -->
          </div>
          <div class="close" @click="close">关闭</div>
        </div>
      </div>
      <div class="view-box">
        <div class="left-box">
          <div class="view-title">原文件</div>
          <div class="diff-box">
            <div class="oper-box">
              <div
                class="view-content origin"
                @mousemove.stop="onMousemove"
                @mousedown.stop="onMousedown"
                data-key="parent"
                :style="{ top: state.imgY + 'px', left: state.imgX + 'px', transform: `scale(${state.scale})` }"
              >
                <img :src="state.current.fullRawFile" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div :class="['match-ratio', state.current?.meetType == 1 ? 'success' : 'erorr']">
          匹配率{{ state.current.chance }}%
        </div>
        <div class="right-box">
          <div class="view-title">比对文件</div>
          <div class="diff-box" ref="comparisonDom">
            <div class="oper-box">
              <div
                class="view-content"
                @mousemove.stop="onMousemove"
                @mousedown.stop="onMousedown"
                data-key="parent"
                :style="{ top: state.imgY + 'px', left: state.imgX + 'px', transform: `scale(${state.scale})` }"
              >
                <img ref="imgDom" :src="state.current.fullSource" alt="" />
                <div ref="diffDom"></div>
              </div>
            </div>
            <div v-show="state.tipShow" class="tip" :style="{ left: state.tipX + 'px', top: state.tipY + 'px' }">
              {{ state.tipContent }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-content">
          <div class="left-btn" @click="prev">上一页</div>
          <div class="page">
            <span class="cur-page">{{ state.page.curentIndx }}</span>
            <span>/</span>
            <span class="all-page">{{ state.page.total }}</span>
          </div>
          <div class="right-btn" @click="next">下一页</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, defineProps, defineEmits, defineExpose, watch, nextTick } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  ocrFileVoList: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:visible'])

const state = reactive({
  imgY: 0,
  imgX: 0,
  innerY: 0,
  innerX: 0,
  tipX: 0,
  tipY: 0,
  isDown: false,
  scale: 1,
  tipContent: '',
  tipShow: false,
  page: {
    total: 10,
    curentIndx: 1
  },
  current: {}
})

const rectConfig = reactive({
  className: ['add', 'del', 'mod'],
  tipTitle: ['增加：', '删除：', '修改：']
})
//存放色块的容器
const diffDom = ref(null)
//tip的父级容器
const comparisonDom = ref(null)
//对比文件dom
const imgDom = ref(null)

//阻止默认事件
const pauseEvent = e => {
  if (e.stopPropagation) e.stopPropagation()
  if (e.preventDefault) e.preventDefault()
  e.cancelBubble = true
  e.returnValue = false
  return false
}

//鼠标移动
const onMousemove = e => {
  if (!state.isDown) return

  state.imgX = e.clientX - state.innerX
  state.imgY = e.clientY - state.innerY
}

//鼠标按下
const onMousedown = e => {
  state.isDown = true
  //阻止冒泡与默认事件
  pauseEvent(e)
  state.innerY = e.clientY - e.currentTarget.offsetTop
  state.innerX = e.clientX - e.currentTarget.offsetLeft
}

//鼠标按下抬起
const onMouseUp = () => {
  console.log('up')
  state.isDown = false
}

//去除默认事件
const scrollFunc = e => {
  e = e || window.event
  //   e.preventDefault()
  // 火狐下没有wheelDelta，用detail代替，由于detail值的正负和wheelDelta相反，所以取反
  e.delta = e.wheelDelta || -e.detail

  if (e.delta > 0) {
    if (state.scale <= 0.1) return
    state.scale -= 0.05
  }

  if (e.delta < 0) {
    if (state.scale > 2) return
    state.scale += 0.05
  }
}

//弹窗关闭
const close = () => {
  rest()
  emit('update:visible', false)
}

//计算tip （left，right）偏移量
const getXAndY = (target, parent) => {
  //获取目标元素距离视图窗口x，y坐标
  let targetRect = target.getBoundingClientRect()
  let targetX = targetRect.x
  let targetY = targetRect.y
  let targetW = targetRect.width
  let targetH = targetRect.height
  //tip相对定位的父级距离视口的距离
  let viewX = parent.offsetLeft
  let ViewY = parent.offsetTop
  return { x: targetX - viewX + targetW, y: targetY - ViewY + targetH }
}

//鼠标移入色块
const nodeMouseenter = (e, item) => {
  const { x, y } = getXAndY(e.target, comparisonDom.value)
  state.tipX = x
  state.tipY = y
  state.tipContent = `${rectConfig.tipTitle[item.problemType - 1]}${item.word}`
  state.tipShow = true
}

//鼠标移出色块
const nodeMouseleave = e => {
  state.tipShow = false
}

//重置所有偏移量坐标
const rest = () => {
  state.imgY = 0
  state.imgX = 0
  state.innerY = 0
  state.innerX = 0
  state.tipX = 0
  state.tipY = 0
  state.isDown = false
  state.scale = 1
  state.tipContent = ''
  state.tipShow = false
  diffDom.value.innerHTML = ''
}

//创建htmlElement
const createNode = (item, radio) => {
  const div = document.createElement('div')
  div.className = rectConfig.className[item.problemType - 1]
  div.style.width = item.w * radio + 'px'
  div.style.height = item.h * radio + 'px'
  div.style.position = 'absolute'
  div.style.left = item.x * radio + 'px'
  div.style.top = item.y * radio + 'px'
  div.addEventListener('mouseenter', e => nodeMouseenter(e, item))
  div.addEventListener('mouseleave', nodeMouseleave)
  return div
}

const domInit = type => {
  //初始化当页数
  state.current = props.ocrFileVoList[state.page.curentIndx - 1]
  //重置坐标
  rest()
  //图片相对于原始尺寸的比例
  let radio = 505 / imgDom.value.naturalWidth
  //创建色块
  if (type == 'mounted') return
  const elements = state.current.ocrFileContent.map(item => {
    const element = createNode(item, radio)
    return element
  })
  //插入页面中
  diffDom.value.append(...elements)
}

//上一页
const prev = () => {
  const { total, curentIndx } = state.page
  state.page.curentIndx -= 1
  if (curentIndx <= 1) {
    state.page.curentIndx = total
  }
  pageInit(state.page.curentIndx)
}
//下一页
const next = () => {
  const { total, curentIndx } = state.page
  state.page.curentIndx += 1
  if (curentIndx >= total) {
    state.page.curentIndx = 1
  }
  pageInit(state.page.curentIndx)
}

const listener = () => {
  //监听鼠标滚动与抬起事件
  document.body.onmousewheel = scrollFunc
  window.addEventListener('DOMMouseScroll', scrollFunc)
  window.addEventListener('mouseup', onMouseUp)
}

const removeListener = () => {
  document.body.onmousewheel = null
  window.removeEventListener('DOMMouseScroll', scrollFunc)
  window.removeEventListener('mouseup', onMouseUp)
}

const pageInit = (curentIndx = 1, type) => {
  nextTick(() => {
    state.page.total = props.ocrFileVoList.length
    state.page.curentIndx = curentIndx
    domInit(type)
  })
}

watch(
  () => props.visible,
  () => {
    if (props.visible) {
      listener()
    } else {
      removeListener()
    }
  }
)

//这个得调一下，确保第一次拿到的图片naturalWidth正确（具体啥原因还待确认）
onMounted(() => {
  pageInit(1, 'mounted')
})

defineExpose({
  pageInit
})
</script>
<style lang="less" scoped>
@import url(./index.less);
</style>
