<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-27 16:56:09
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-07 17:03:23
 * @FilePath: \yda_web_manage\src\components\detailForm\components\sealList\components\sealImgList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="image-info-list-box">
    <div v-for="(val, key, index) in $attrs" :key="index" class="image-list-item-box">
      <p class="title" :style="{ paddingTop: index > 0 ? '24px' : spacing }">{{ key }}</p>
      <div v-viewer="printImgOptions" v-if="key !== '用印视频' && key !== '远程确认视频' && key !== 'OCR对比记录'">
        <section class="show-img-section" v-if="val.length < 1">无</section>
        <section class="show-img-section" v-else>
          <ImageInfoItem :image-data="item" v-for="(item, inde) in val" :key="inde" />
        </section>
      </div>
      <div class="ocr-box" v-else-if="key == 'OCR对比记录'">
        <section class="show-img-section" v-if="val.length < 1">无</section>
        <OcrDiff :ocrFileVoList="val" />
      </div>
      <div v-else class="show-content">
        <VideoList :videoList="val" :showStaffName="true" iconUrl="assets/images/playIcon.png" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import VideoList from '@/components/VideoList'
import ImageInfoItem from './imageInfoItem.vue'
import OcrDiff from './ocrDiff.vue'
const printImgOptions = ref({
  navbar: false,
  title: false,
  transition: false,
  viewed(e) {
    if (e.detail.originalImage.dataset.imagetype == 1) {
      this.viewer.rotateTo(0)
    }
  }
})
const props = defineProps({
  spacing: {
    type: String,
    default: '16px'
  }
})
</script>
<style scoped lang="less">
.image-info-list-box {
  margin-left: -11px;
  .image-list-item-box {
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    padding-bottom: 24px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin: 0 0 8px 11px;
    }
    .show-img-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .show-img-div {
        width: 104px;
        height: 104px;
        .show-img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          margin-right: 16px;
          margin-bottom: 8px;
        }
      }
    }
    .show-content {
      .show-img-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .show-img-div {
          width: 104px;
          height: 104px;
          margin-right: 16px;
          margin-bottom: 8px;

          .show-img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }

    :deep(div.image-list-module) {
      //视频
      .video-item {
        text-align: center;
        .video-box {
          position: relative;
          width: 104px;
          height: 104px;
          border: none;
          padding: 0;
          margin-right: 0;
          margin-bottom: 8px;
          display: inline-block;
          .min-video {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
          .play-image {
            width: 30px;
            height: 30px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
  .image-list-item-box:last-child {
    border: none;
    padding-bottom: 0;
  }
}
</style>
