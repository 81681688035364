<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-26 16:27:53
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-12 11:10:23
 * @FilePath: \yda_web_manage\src\components\detailForm\components\sealList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="seal-list-box">
    <div
      class="seal-item-box"
      v-for="(item, index) in $attrs.value"
      :key="index"
      :class="{ 'bottom-border': item.type !== 3 }"
    >
      <div class="flex-layout-between seal-name-head">
        <p class="seal-name">{{ item.sealName }}</p>
        <!-- <template v-if="(isDetail && item.status == 1) || (isDetail && item.status == 2)">
          <UrgButton @urgOk="handleUrgOk" tipsType="staff" v-if="item.urgInfo.urge" v-bind="item.urgInfo"></UrgButton>
          <UrgeRecord v-if="item.urgInfo.urgeRecord" :recordInfo="item.urgInfo.urgeRecord" type="staff"></UrgeRecord>
        </template> -->
      </div>

      <!-- 盖印人、申请次数、实际盖印次数 -->
      <div class="sela-basic-info-box" :class="getClassName(item.type, item.fillCover)">
        <div
          class="basic-info-item"
          v-for="(val, key, inde) in item.basicInfo"
          :key="inde"
          :style="getStyle(item.type, inde)"
        >
          <span class="info_key">{{ key }}：</span>
          <div v-if="item.type !== 3 && inde === 0" class="info_value">{{ val }}次</div>
          <div v-if="item.type !== 3 && inde > 0">
            <div class="cover-seal-name-list">
              <Tooltip :value="el" v-for="(el, ind) in val" :key="ind" />
            </div>
          </div>
          <div v-if="item.type === 3 && inde === 0" class="cover-name">{{ val.join('、') || '无' }}</div>
          <div v-if="item.type === 3 && inde > 0" class="info_value">{{ val }}次</div>
        </div>
      </div>
      <!-- 记录详情使用其中为补盖、影像等信息 -->
      <component :is="components['FillConver']" v-bind="item.fillCover" v-if="item.fillCover" />
      <component
        v-if="item.type === 3 && item.imageData"
        :is="components['ImageInfoList']"
        v-bind="item.imageData"
        :spacing="item.fillCover ? '16px' : '24px'"
      />
      <!-- 审批信息 -->
      <div class="archaic-approve-box" v-if="item.commonFlowList && item.type === 3">
        <p>审批信息</p>
        <ApprovalFlow :approvalInfo="item.commonFlowList" :isApprove="true" v-if="item.commonFlowList" />
      </div>
      <ApprovalFlow
        :approvalInfo="item.commonFlowList"
        :isApprove="true"
        v-if="item.commonFlowList && item.type !== 3"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, toRefs, onBeforeMount, onMounted, useAttrs, inject, ref } from 'vue'
import components from '../../componentExport'
import Tooltip from '../tooltip.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import UrgButton from '@/components/UrgButton/index.vue'
import UrgeRecord from '@/components/UrgRecord/index.vue'
import { cmsNotice } from '@/utils/utils'
import { useRoute } from 'vue-router'
const isDetail = ref(false) // 是否是申请详情页
const isSealRecord = ref(false) //是否是用印记录页面
const route = useRoute()
const getStyle = computed(() => (type, index) => {
  if (index < 1) {
    return type === 1 ? { marginRight: '120px' } : { maxWidth: '334px', marginRight: '51px' }
  }
  return {}
})
const getClassName = computed(() => (type, cover) => {
  if (type === 2 && cover) return 'border-bottom'
  if (type === 3 && !cover) return 'border-bottom'
  return ''
})
// 获取向上边距
const getUpwardMargin = computed(() => (cover, imageData) => {
  if (Object.keys(imageData).length > 1) {
    return cover ? '16px' : '24px'
  }
  return cover ? '16px' : '0px'
})
// 催办结果
function handleUrgOk(data) {
  console.log('催办成功吗盖印人', data)
}
onMounted(() => {
  console.log(useAttrs(), '我是useAttrs')
  console.log('route.path', route.path)
  isDetail.value = route.path === '/seal/apply/detail'
  isSealRecord.value = route.path === '/seal/applyList/detail'
})
</script>
<style scoped lang="less">
.seal-list-box {
  .seal-item-box {
    padding: 16px 0;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    :deep(.fill-cover-box) {
      .info-box {
        .title {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .seal-name-head {
      margin-bottom: 8px;
    }
    .seal-name {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .sela-basic-info-box {
      display: flex;
      .basic-info-item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        span {
          flex-shrink: 0;
        }
        .cover-seal-name-list {
          width: 784px;
          display: flex;
          flex-wrap: wrap;
          row-gap: 6px;
          .tooltip-box:last-child {
            margin-right: 0;
          }
          .tooltip-box {
            background: #fbfbfc;
          }
        }
        .cover-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .border-bottom {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(233, 233, 233, 1);
    }
    .archaic-approve-box {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid #e9e9e9;
      p {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      :deep(.approve-box) {
        padding-bottom: 0;
      }
    }
  }
  .seal-item-box:first-child {
    padding-top: 0;
  }
  .seal-item-box:last-child {
    border: none;
    padding-bottom: 0;
  }
  .bottom-border {
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }
}
</style>
