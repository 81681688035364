<template>
  <template v-if="pageLoading">
    <a-spin size="large" class="loading-animation" />
  </template>
  <template v-else>
    <div class="approval-detail">
      <!-- 头部信息 -->
      <DetailHeader
        :headerInfo="headerInfo"
        :showTime="false"
        :showSection="true"
        :showSealStatus="false"
        :showApplyStatus="false"
        :showSealRecord="false"
        :isAppoval="false"
      >
      </DetailHeader>
      <!-- 内容 -->
      <div
        :class="[
          'defalut-padding',
          hasPending && $route.query.hasCover === 'false' ? 'small-top-padding' : 'big-top-padding',
          hasPending ? 'big-bottom-padding' : 'small-bottom-padding'
        ]"
      >
        <DetaiForm :formInfo="formInfo" />
        <!-- 文件归档 -->
        <ArchiveInfo :archiveInfos="archiveInfos" :isArchiveManage="true" :id="id" @reArchive="getDetail()" />
        <!-- 审批信息 -->
        <div class="approval-info" v-if="approvalInfo.length > 0">
          <div class="model-title">审批信息</div>
          <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true"></ApprovalFlow>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, toRaw, createVNode, nextTick } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import { useRoute, useRouter } from 'vue-router'
import { getArchiveDetail, approve } from '@/apis/archive'
import { Modal, notification } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { cmsMessage } from '@/utils/utils'
import ArchiveInfo from '@/components/Archive/index.vue'
export default defineComponent({
  components: {
    DetailHeader,
    ApprovalFlow,
    DetaiForm,
    ArchiveInfo
  },
  setup() {
    const pageLoading = ref(true)
    const route = useRoute()
    const router = useRouter()
    const refuseLoading = ref(false)
    const agreeLoading = ref(false)

    //审批接口参数
    const data = reactive({
      documentId: undefined, //文件id
      opinion: '', //审批意见
      params: {}, //其他参数
      staffId: undefined, //审批人id
      taskId: route.query.taskId, //审批任务id
      state: undefined, //当前审批人的状态 3同意、4拒绝
      processMold: route.query.processMold
    })
    //审批意见弹窗
    const modelState = reactive({
      visible: false,
      opinion: ''
    })
    //头部固定信息
    const headerInfo = ref({})
    // 表单部分数据
    const formInfo = ref({})
    //全局审批流程信息
    const approvalInfo = ref([])

    const hasPending = ref(true) //当前用户是否处于审批
    //有无归档权限
    const hasArchive = route.query.dataId
    //获取待审批/已审批详情
    const archiveInfos = ref({})
    const id = route.query.id
    const getDetail = () => {
      getArchiveDetail(route.query.id)
        .then(res => {
          if (res.success) {
            console.log('66666666', res.data)
            archiveInfos.value = res.data.documentArchiveDetailsVO
            console.log('777777777777777', archiveInfos.value)
            headerInfo.value = res.data
            const items = JSON.parse(res.data.formItems)
            const index = items.findIndex(item => item.type === 'FILE_NAME')
            headerInfo.value['fileName'] = res.data.formData[items[index].id][0].fileName || ''
            approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
            data.documentId = res.data.documentId
            data.params = res.data.params
            hasPending.value = res.data.hasPending
            formInfo.value = {
              formData: res.data.formData,
              formItems: res.data.formItems,
              hasArchaic: res.data.hasArchaic,
              fileElseList: res.data.fileElseList,
              hasCover: res.data.hasCover,
              fileCheckList: res.data.fileCheckList,
              documentId: res.data.documentId,
              qrcodeData: {
                qrcodeDiffList: res.data.qrcodeDiffList,
                qrcodeType: res.data.qrcodeType,
                qrcodePositionType: res.data.qrcodePositionType,
                qrcodeSize: res.data.qrcodeSize
              },
              status: res.data.status
            }
            console.log('自定义表单部分', formInfo.value)
            pageLoading.value = false
          } else {
            pageLoading.value = false
          }
        })
        .catch(error => {
          console.log(error)
          pageLoading.value = false
        })
    }
    onMounted(async () => {
      data.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId
      data.taskId = route.query.taskId
      getDetail()
      console.log('看看里面有什么', approvalInfo)
    })
    return {
      headerInfo,
      formInfo,
      approvalInfo,
      getDetail,
      modelState,
      refuseLoading,
      agreeLoading,
      hasPending,
      pageLoading,
      hasArchive,
      archiveInfos,
      id
    }
  }
})
</script>

<style lang="scss" scoped>
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.approval-detail {
  .defalut-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .big-top-padding {
    padding-top: 16px;
  }

  .small-top-padding {
    padding-top: 16px;
  }

  .big-bottom-padding {
    padding-bottom: 56px;
  }

  .small-bottom-padding {
    padding-bottom: 16px;
  }

  .approval-info {
    background-color: #fff;
    margin-top: 20px;

    .model-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      padding: 16px 24px;
      border-bottom: 1px #e9e9e9 solid;
    }
  }

  .foot-btn {
    width: calc(100vw - 208px);
    padding: 12px 24px;
    text-align: right;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

    .btn {
      margin-right: 8px;
    }
  }
}
</style>
