<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-25 19:47:26
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-12 14:05:01
 * @FilePath: \yda_web_manage\src\components\detailForm\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="form-region-box">
    <div v-for="(item, index) in state.dataList" :key="index" class="card-box">
      <component
        :is="componentName(el)"
        v-for="(el, index) in item"
        :key="'i' + index"
        v-bind="{ ...el, documentId: formInfo.documentId }"
      />
    </div>
    <!-- 二维码校验记录 -->
    <component
      v-if="isSealRecord"
      :is="components['QrCodeList']"
      :qrcodeDiffList="formInfo?.qrcodeData?.qrcodeDiffList"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  computed,
  defineProps,
  watchEffect,
  onMounted,
  ref
} from 'vue'
import components from './componentExport'
import { dealDetailData } from '@/utils/utils'
import { useRoute, useRouter } from 'vue-router'

// 拿接口返回的controlName去一一对应显示什么组件
const componentName = computed(() => (item) => {
  console.log('componentNameitem', item)
  if (item.controlName === 'TextInput' && item.type) {
    return components['FileName']
  }
  return components[item.controlName]
})
const state = reactive({
  dataList: []
})
const isSealRecord = ref(false) //是否是用印记录页面
const route = useRoute()
const props = defineProps({
  formInfo: {
    type: Object,
    default: () => ({}),
    required: true
  }
})
onMounted(() => {
  console.log('formInfo', props.formInfo)
  // isSealRecord.value = route.path === '/seal/applyList/detail'
})
watchEffect(async () => {
  let fomrInfoItems = []
  if (props.formInfo.formItems) {
    fomrInfoItems = JSON.parse(props.formInfo.formItems)
  }
  console.log(fomrInfoItems, '我是空间数组')
  // console.log(route.path, '我是当前路由')
  console.log(props.formInfo.sealStatus, '当前是待用印还是用印中')
  // 0抄送 、 申请详情 1 、审批详情 2 、 记录详情 3
  const list = [
    '/seal/apply/detail',
    'pendingdetail',
    '/seal/applyCompleteList/detail'
  ]
  let flag
  if (route.path === '/approval/approved/approveddetail') {
    flag = 2
  } else if (
    // eslint-disable-next-line no-constant-condition
    route.path === '/seal/applyCompleteList/detail' ||
    route.path === '/seal/applyArchiveList/detail' ||
    route.path === '/archiveManage/detail'
  ) {
    flag = 3
  } else if (route.path === '/approval/informPeople/detail') {
    flag = 0
  } else if (route.path === '/seal/apply/detail') {
    flag = 1
  } else if (
    route.path == '/seal/applyWaitList/detail' &&
    props.formInfo.sealStatus == 1
  ) {
    flag = 1
    //待用印中 用印中展示记录 待用印展示申请
  } else if (
    route.path == '/seal/applyWaitList/detail' &&
    props.formInfo.sealStatus == 2
  ) {
    flag = 3
  } else if (
    (route.path === '/approval/notApproval/pendingdetail' ||
      route.path === '/approval/alreadyApproval/approveddetail') &&
    route.query.processMold == '1'
  ) {
    flag = 2
  } else if (
    (route.path === '/approval/notApproval/pendingdetail' ||
      route.path === '/approval/alreadyApproval/approveddetail') &&
    route.query.processMold == '2'
  ) {
    flag = 3
  } else {
    flag = list.findIndex((item) => route.path.includes(item)) + 1
  }
  console.log(flag, 'flag')
  //二维码 //记录详情 & 且不为申请 & 为归档审批 ｜ 归档管理
  isSealRecord.value = [3].includes(flag)
  console.log('codeDatacodeData', props.formInfo.codeData)
  state.dataList = await dealDetailData(
    props.formInfo.formData,
    fomrInfoItems,
    flag,
    props.formInfo.hasArchaic,
    props.formInfo.fileElseList,
    props.formInfo.hasCover,
    props.formInfo.codeData,
    props.formInfo.qrcodeData,
    props.formInfo.fileCheckList,
    route.query.processMold,
    props.formInfo.status
  )
})
</script>
<style scoped lang="less">
.form-region-box {
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;

  .card-box {
    background: white;
    margin-top: 16px;
    border-radius: 2px;
    padding: 16px 24px;

    div:last-child {
      margin-bottom: 0px;
    }
  }

  .card-box:first-child {
    margin-top: 0;
  }
}
</style>
