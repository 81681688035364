<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-26 10:36:35
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-04 13:45:55
 * @FilePath: \yda_web_manage\src\components\detailForm\components\fileUpload.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="file-upoad-box">
    <div class="title">{{ $attrs.titles }}：</div>
    <div class="file-list-box">
      <!-- 二维码 -->
      <div v-if="$attrs.qrcodeType === 1 && $attrs.type === 'FILE'" class="qr-code">
        <img class="img" :src="require('@/assets/images/qr-code.png')" alt="" />
        <span>{{ showQrcode($attrs) }}</span>
      </div>
      <div class="without" v-if="!$attrs.value || $attrs.value.length < 1">无</div>
      <div v-viewer="printImgOptions" v-else>
        <div class="show-img-section">
          <div class="file-list-item" v-for="(item, index) in $attrs.value" :key="index">
            <div class="file-img-box">
              <div class="file-img" :style="getStyle(item)">
                <img :src="getImgUrl(item)" alt="" />
              </div>
              <div class="load-icon-box" @click="downloadFile(item.fullFile)">
                <i class="iconfont icon-xiazai icons" style="color:white;font-size:12px;"></i>
              </div>
            </div>
            <div class="file-name" v-if="(item.fileName + '.' + item.suffix).length < 28">
              {{ item.fileName + '.' + item.suffix }}
            </div>
            <a-tooltip placement="bottomLeft" overlayClassName="seal-trustee-overlay" v-else>
              <template #title>
                <span>
                  {{ item.fileName + '.' + item.suffix }}
                </span>
              </template>
              <div class="file-name">
                {{ item.fileName + '.' + item.suffix }}
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, onBeforeMount, onMounted } from 'vue'
import { cmsNotice } from '@/utils/utils'
const printImgOptions = ref({
  navbar: false,
  title: false,
  transition: false,
  viewed(e) {
    if (e.detail.originalImage.dataset.imagetype == 1) {
      this.viewer.rotateTo(0)
    }
  }
})
const getImgUrl = computed(() => item => {
  const obj = {
    docx: require('@/assets/images/word.png'),
    doc: require('@/assets/images/word.png'),
    xls: require('@/assets/images/excel.png'),
    xlsx: require('@/assets/images/excel.png'),
    pdf: require('@/assets/images/pdf.png'),
    text: require('@/assets/images/txt.png')
  }
  return obj[item.suffix] ? obj[item.suffix] : item.fullFile
})
const getStyle = computed(() => item => {
  const list = ['docx', 'doc', 'xls', 'xlsx', 'pdf', 'text']
  return list.includes(item.suffix) ? { border: '1px dashed rgba(219, 219, 219, 1)' } : {}
})
const downloadFile = src => {
  window.location.href = src.replace('.com//', '.com/') + '?upload=true'
  setTimeout(() => {
    cmsNotice('success', '正在为你下载，请耐心等待')
  }, 200)
}

// 回显二维码参数
const showQrcode = data => {
  // 如果没开启二维码用印则逻辑不必继续执行
  if (data.qrcodeType != 1) return
  let textFront = '25*25mm'
  let textAfter = '左上'
  switch (data.qrcodePositionType) {
    case 1:
      textAfter = '左上'
      break
    case 2:
      textAfter = '右上'
      break
    case 3:
      textAfter = '左下'
      break
    case 4:
      textAfter = '右下'
  }
  switch (data.qrcodeSize) {
    case 1:
      textFront = '20*20mm'
      break
    case 2:
      textFront = '25*25mm'
      break
    case 3:
      textFront = '30*30mm'
      break
    case 4:
      textFront = '35*35mm'
  }
  return `${textFront} ${textAfter}`
}
</script>
<style scoped lang="less">
.file-upoad-box {
  display: flex;
  margin-bottom: 8px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
    flex-shrink: 0;
  }
  .file-list-box {
    .img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .qr-code {
      height: 24px;
      background: #f5f6f7;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
    }
    .file-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &:hover {
        .file-img-box {
          .load-icon-box {
            display: block;
          }
        }
      }
      .file-img-box {
        cursor: pointer;
        margin-right: 12px;
        position: relative;
        .file-img {
          width: 44px;
          height: 44px;
          border-radius: 2px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .load-icon-box {
          position: absolute;
          width: 100%;
          height: 15px;
          text-align: center;
          line-height: 11px;
          background-color: rgba(0, 0, 0, 0.45);
          display: none;
          bottom: 0;
          left: 0;
        }
      }

      .file-name {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        color: rgba(51, 51, 51, 0.65);
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .file-list-item:last-child {
      margin-bottom: 0;
    }
    .without {
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.65);
      line-height: 20px;
    }
  }
}
.show-img-section {
  max-height: 348px;
  width: 460px;
  box-sizing: border-box;
  padding: 8px;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  &::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    display: block;
    overflow-y: scroll;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 51, 0.1);
    border-radius: 2px;
  }
}
</style>
