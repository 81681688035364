<template>
  <div class="file-manage">
    <iframe width="100%" height="100%" ref="iframeRef" :src="fileSrc"> </iframe>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getRoute } from '@/apis/user'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
const router = useRouter()
const iframeRef = ref()
const fileSrc = ref('')
onMounted(() => {
  window.addEventListener('message', messageHandle)
  routeData()
})
onBeforeRouteLeave(() => {
  window.removeEventListener('message', messageHandle)
})
const messageHandle = (e) => {
  console.log('message', e.data)
  toApply(e.data)
}
const routeData = async () => {
  getRoute({
    enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo'))
      .enterpriseId,
    staffId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).staffId
  }).then((res) => {
    console.log('hhhhhhhhhhh', res)
    if (res.success) {
      fileSrc.value = res.data
    }
  })
}
const toApply = (e) => {
  console.log('999', e)
  if (e.processId == '' || e.processId == null) return
  localStorage.setItem(
    'iframeData',
    JSON.stringify({
      pdfUrl: e.pdfUrl ?? '',
      processId: e.processId ?? '',
      flowName: e.flowName ?? ''
    })
  )
  router.push('/seal/apply')
}
</script>

<style>
.file-manage {
  position: absolute;
  top: 148px;
  right: 0;
  left: 208px;
  bottom: 0;
}
</style>
