import  oneImg  from '@/assets/images/sealMessage/commonSeal.png'
import twoImg from '@/assets/images/sealMessage/dedicated-seal.png'
import threeImg from '@/assets/images/sealMessage/league-member-seal.png'
import fourImg from '@/assets/images/sealMessage/foreignCompanySeal-one.png'
import fiveImg from '@/assets/images/sealMessage/foreignCompanySeal-two.png'

export const sealMessage = [
  {
    sealImageUrl: oneImg,
    sealName: '公章',
    remark: '',
    diameterList: ['直径40mm', '直径42mm', '直径45mm'], // 印章直径选项
    diameterDisable: false, // 印章直径是否禁用
    sealSize: [], // 印章尺寸（长*宽）
    roundEdgeWidth: ['1.2mm'],  // 圆边宽选项
    widthDisable: true,  // 圆边宽是否禁用
    pentacleDiameter: ['14mm*14mm'],  // 五角星直径选项
    isShowBottomText: false,  // 是否展示下方文字
    surround: true, // 是否展示环绕问题
    externalSize: [], // 外圆尺寸（长*宽）
    interiorSize: [], // 内圆尺寸（长*宽）
    circleSideSize: [], // 圆边宽（外/内）
  },
  {
    sealImageUrl: twoImg,
    sealName: '专用章',
    remark: '（不带五角星）',
    diameterList: ['直径42mm', '直径45mm'], // 印章直径选项
    diameterDisable: false, // 印章直径是否禁用
    sealSize: [], // 印章尺寸（长*宽）
    roundEdgeWidth: ['1.2mm'],  // 圆边宽选项
    widthDisable: true,  // 圆边宽是否禁用
    pentacleDiameter: [],  // 五角星直径选项
    isShowBottomText: true,  // 是否展示下方文字
    surround: true, // 是否展示环绕问题
    externalSize: [], // 外圆尺寸（长*宽）
    interiorSize: [], // 内圆尺寸（长*宽）
    circleSideSize: [], // 圆边宽（外/内）
  },
  {
    sealImageUrl: threeImg,
    sealName: '共青团章',
    remark: '',
    diameterList: ['直径40mm', '直径44mm', '直径45mm'], // 印章直径选项
    diameterDisable: false, // 印章直径是否禁用
    sealSize: [], // 印章尺寸（长*宽）
    roundEdgeWidth: ['1.2mm', '1.5mm'],  // 圆边宽选项
    widthDisable: false,  // 圆边宽是否禁用
    pentacleDiameter: ['16mm*16mm'],  // 五角星直径选项
    isShowBottomText: true,  // 是否展示下方文字
    surround: true, // 是否展示环绕问题
    externalSize: [], // 外圆尺寸（长*宽）
    interiorSize: [], // 内圆尺寸（长*宽）
    circleSideSize: [], // 圆边宽（外/内）
  },
  {
    sealImageUrl: fourImg,
    sealName: '外资企业公章',
    remark: '（纯中文）',
    diameterList: [], // 印章直径选项
    sealSize: ['45mm*30mm'], // 印章尺寸（长*宽）
    roundEdgeWidth: ['1.2mm'],  // 圆边宽选项
    widthDisable: true,  // 圆边宽是否禁用
    pentacleDiameter: [],  // 五角星直径选项
    isShowBottomText: true,  // 是否展示下方文字
    surround: true, // 是否展示环绕问题
    externalSize: [], // 外圆尺寸（长*宽）
    interiorSize: [], // 内圆尺寸（长*宽）
    circleSideSize: [], // 圆边宽（外/内）
  },
  {
    sealImageUrl: fiveImg,
    sealName: '外资企业公章',
    remark: '（中英文）',
    diameterList: [], // 印章直径选项
    sealSize: [], // 印章尺寸（长*宽）
    roundEdgeWidth: [],  // 圆边宽选项
    pentacleDiameter: [],  // 五角星直径选项
    isShowBottomText: false,  // 是否展示下方文字
    surround: false, // 是否展示环绕问题
    externalSize: ['45mm*30mm'], // 外圆尺寸（长*宽）
    interiorSize: ['33mm*18mm'], // 内圆尺寸（长*宽）
    circleSideSize: ['1.0mm/0.5mm'], // 圆边宽（外/内）
  }
]