!<template>
  <div class="notContainer">404 页面找不到了!</div>
</template>

<script>
export default {
  data: function() {
    return {}
  },
  methods: {},
  created() {}
}
</script>

<style lang='less' scoped>
.notContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 60px;
    font-weight: 800;
}
</style>
