/*
 * @Author: cyx 
 * @Date: 2023-04-17 18:15:48
 * @LastEditors: cyx 
 * @LastEditTime: 2023-12-25 16:32:43
 * @FilePath: /yda_web_manage/src/apis/warningCenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 预警中心主页面展示
export function getMessageList(params) {
  return request({
    url: `/pc/message/show`,
    method: 'GET',
    params
  })
}

// 预警中心详情查看
export function getMessageDetails(params) {
  return request({
    url: `/pc/message/details`,
    method: 'GET',
    params
  })
}


// 印章柜预警中心详情查看
export function getCabinetDetails(params) {
  return request({
    url: `/pc/message/cabinet/details`,
    method: 'GET',
    params
  })
}