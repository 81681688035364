<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-26 10:06:27
 * @FilePath: \yda_web_manage\src\views\approval\informPeople\detail_3.vue
 * @Description: 抄送详情3.0
 * 
-->
<template>
  <div class="cc-detail">
    <!-- 头部信息 -->
    <DetailHeader
      :headerInfo="headerInfo"
      :showSealStatus="false"
      :showApplyStatus="false"
      :showSealRecord="true"
      :showTime="true"
      :showSection="true"
    ></DetailHeader>
    <div class="content">
      <DetaiForm :formInfo="formInfo" />
      <!-- 审批信息 -->
      <div class="approval-info" v-if="approvalInfo.length > 0">
        <div class="model-title">审批信息</div>
        <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true"></ApprovalFlow>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import { copyDetail } from '@/apis/approval'
import { useRoute } from 'vue-router'
import DetaiForm from '@/components/detailForm/index.vue'

export default defineComponent({
  components: {
    DetailHeader,
    ApprovalFlow,
    DetaiForm
  },
  setup() {
    const route = useRoute()
    //头部固定信息
    const headerInfo = ref({})
    //表单数据部分
    const formInfo = ref({})
    //审批信息
    const approvalInfo = ref([])
    //获取抄送详情
    const getDetail = () => {
      copyDetail(route.query.dataId||route.query.documentId, {
        processInstanceId: route.query.processInstanceId
      })
        .then(res => {
          if (res.success) {
            headerInfo.value = res.data
            approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
            headerInfo.value.applyTime = headerInfo.value.applyTime.split(' ')[0]
            formInfo.value = {
              formData: res.data.formData,
              formItems: res.data.formItems,
              hasArchaic: res.data.hasArchaic,
              fileElseList: res.data.fileElseList,
              hasCover: res.data.hasCover
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      getDetail()
    })
    return {
      headerInfo,
      approvalInfo,
      getDetail,
      formInfo
    }
  }
})
</script>

<style lang="scss" scoped>
.cc-detail {
  .content {
    padding: 16px 24px 16px;
    .approval-info {
      background-color: #fff;
      .model-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 16px 24px;
        border-bottom: 1px #e9e9e9 solid;
      }
    }
  }
}
</style>
