<!-- v2.6.2 lzk 2022.12.14  -->
<template>
  <a-spin :spinning="loading">
    <section class="seal-detail">
      <a-card class="seal-title">
        <!-- line1 -->
        <a-row style="margin-bottom: 8px" justify="space-between">
          <a-col class="seal-headline" :span="18">
            <a-row>
              <!-- 流程主题 -->
              <!-- :title="current.fileName" -->
              <div class="info-block">
                <span class="info-title">流程主题：</span>
                <a-tooltip
                  :title="current.fileName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.fileName && current.fileName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.fileName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.fileName ?? '--' }}</span>
              </div>
              <!-- 行政区域 -->
              <div class="info-block" v-if="detailType == 1">
                <span class="info-title">行政区域：</span>
                <a-tooltip
                  :title="current.departmentName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.departmentName && current.departmentName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.departmentName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.departmentName ?? '--' }}</span>
              </div>
              <!-- 部门 -->
              <div class="info-block" v-else>
                <span class="info-title"><span style="letter-spacing: 2em;">部</span>门：</span>
                <a-tooltip
                  :title="current.departmentName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.departmentName && current.departmentName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.departmentName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.departmentName ?? '--' }}</span>
              </div>
            </a-row>
          </a-col>
          <!-- 申请状态/用印状态标题 -->
          <!-- <a-col :span="6">
            <a-row justify="end" style="text-align: end">
              <a-col :span="12"
                ><span class="status">{{ detailType === 2 ? '申请状态' : '审批状态' }}</span></a-col
              >
              <a-col :span="12" v-if="detailType == 2"><span class="status">用印状态</span></a-col>
            </a-row>
          </a-col> -->
        </a-row>
        <!-- line2 -->
        <a-row justify="space-between">
          <a-col class="seal-headline" :span="18">
            <a-row>
              <!-- 申请人 -->
              <div class="info-block">
                <div class="info-title applicant">
                  <div class="special"><span>申</span><span>请</span><span>人</span></div>
                  ：
                </div>
                <a-tooltip
                  :title="current.staffName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.staffName && current.staffName?.length > 12"
                >
                  <span class="info-content content-wrap">{{ current.staffName ?? '--' }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{ current.staffName ?? '--' }}</span>
              </div>
              <!-- 申请日期： -->
              <div class="info-block seal-date">
                <span class="info-title">申请日期：</span>
                <span class="info-content">{{ current.applyTime ?? '--' }}</span>
              </div>
              <!-- 盖印方式 -->
              <div class="info-block seal-way" v-if="detailType == 2">
                <span class="info-title">盖印方式：</span>
                <span class="info-content" v-if="current.remote === 1">常规盖印</span>
                <span class="info-content" v-if="current.remote === 2">远程盖印</span>
                <span class="info-content" v-if="current.remote === 3">连续盖印</span>
              </div>
            </a-row>
          </a-col>
          <!-- <a-col :span="6">
            <a-row justify="end" style="text-align: end">
              审批状态
              <a-col :span="12">
                <p class="seal-status">
                  <span
                    class="dotted"
                    :style="{ background: examineStatusObj[current.examineStatus ?? 0].color }"
                  ></span
                  >{{ examineStatusObj[current.examineStatus ?? 0].name }}
                </p>
              </a-col>
              用印状态
              <a-col :span="12" v-if="detailType == 2">
                <p class="seal-status">
                  <span class="dotted" :style="{ background: sealStatusObj[current.sealStatus ?? 0].color }"></span
                  >{{ sealStatusObj[current.sealStatus ?? 0].name }}
                </p>
              </a-col>
            </a-row>
          </a-col> -->
        </a-row>
      </a-card>
      <div class="seal-tabs">
        <!-- 自助申请 -->
        <a-card title="自助申请" v-if="detailType == 1" style="margin-bottom: 16px">
          <a-row sign="3" align="top" style="margin-bottom: 8px">
            <a-col :span="7" style="display:flex">
              <span class="label">申请人：</span>
              <div style="margin-left:1.75rem">{{ current.selfUserName ?? '--' }}</div>
            </a-col>
          </a-row>
          <a-row sign="3" align="top" style="margin-bottom: 8px">
            <a-col :span="7" style="display:flex">
              <span class="label">手机号：</span>
              <div style="margin-left:1.75rem">{{ current.selfUserMobile ?? '--' }}</div>
            </a-col>
          </a-row>
        </a-card>
        <!--用印列表-->
        <div v-for="(item, index) in current.sealShow" :key="index">
          <a-card style="margin-bottom: 16px;" class="process">
            <template #title>
              <div>
                <!-- 印章名称 -->
                <div style="margin-bottom:8px">{{ item.sealName }}</div>
                <a-row>
                  <!-- 申请次数 -->
                  <a-col :span="7" style="display:flex">
                    <span class="label">申请次数:</span>
                    <span class="value">{{ item.applicationNumber }}次</span>
                  </a-col>
                  <!-- 盖印人 -->
                  <a-col :span="7" style="display:flex">
                    <span class="label">盖印人：</span>
                    <section class="value value-omit">
                      <SealStaff :list="item.sealStaffRelVOList"  v-if="item?.sealStaffRelVOList?.length" />
                      <span v-else>无</span>
                    </section>
                    <!-- <span class="value value-omit" :title="item.affixedStaffName">{{ item.affixedStaffName }}</span> -->
                  </a-col>
                </a-row>
              </div>
            </template>
            <!-- <template #extra v-if="item.isRecordBtn">
              <a
                class="tab-btn"
                @click="$router.push(`/seal/applyList/detail?id=${item.documentId}&docType=1&sealId=${item.sealId}`)"
              >
                <div>用印记录</div>
                <RightOutlined />
              </a>
            </template> -->
            <approval-time-line-list :approvalList="list[index] || []" />
          </a-card>
        </div>

        <a-card title="外带信息" style="margin-bottom: 16px" v-if="current.takeout == 2">
          <a-row>
            <a-col :span="24" style="margin-bottom: 8px">
              <span class="lable">外带时间：</span>
              <span class="value">{{ current.startTime ? `${current.startTime}-${current.finishTime}` : '无' }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24" style="display:flex;align-items: flex-start;">
              <span class="lable">外带地点：</span>
              <span class="value">{{ current.address == '' || current.address == null ? '无' : current.address }}</span>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="远程信息" style="margin-bottom: 16px" v-if="current.remote == 2">
          <!-- 远程确认人 -->
          <a-row
            :style="
              current.documentRemoteVice && current.documentRemoteVice.length ? 'margin-bottom: 8px' : 'margin-bottom:0'
            "
          >
            <a-col :span="24">
              <span class="lable">远程确认人：</span>
              <span class="value">{{ current.remoteStaffName ?? '无' }}</span>
            </a-col>
          </a-row>
        </a-card>
        <!-- 连续盖印信息 -->
        <a-card title="连续盖印信息" style="margin:24px 0;" v-if="current.remote === 3">
          <a-row style="margin-bottom: 8px">
            <a-col :span="24">
              <span class="lable">连续盖印：</span>
              <span class="value">已开启</span>
            </a-col>
          </a-row>
        </a-card>
        <!-- 其他信息 -->
        <a-card title="其他信息">
          <a-row style="margin-bottom: 8px">
            <a-col :span="24">
              <span class="lable">申请事由：</span>
              <span class="value">{{ current.applyText ? current.applyText : '无' }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <span class="lable">附件：</span>
              <span v-if="!current.documentApplyFileS">无</span>
              <section style="display: inline-block; vertical-align: top;width:80%;" v-else>
                <div v-viewer="otherOptions" :images="current.imgList">
                  <div class="applyFileBox" v-for="(it, index) in current.documentApplyFileS" :key="it.fileId">
                    <div class="seal-imgs" style="position: relative;">
                      <a v-if="fileTypeObj[it.suffix]">
                        <img :src="getImgUrl(it.suffix)" class="seal-img" alt="" />
                      </a>
                      <div class="downloadFileBox" @click="downloadFile(it.fullFile)" v-if="fileTypeObj[it.suffix]">
                        <i class="iconfont icon-xiazai icons" style="color:white;font-size:25px;"></i>
                      </div>
                      <img
                        v-else
                        :src="it.fullFile"
                        alt=""
                        @error="reImg($event, it.fullFile)"
                        @click="previewImg(it.fullFile, index, 'applyFile')"
                        class="seal-img"
                      />
                    </div>
                    <div style="flex:1" :title="it.fileName" class="single-wrap">{{ it.fileName ?? '无' }}</div>
                  </div>
                </div>
              </section>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </section>
  </a-spin>
  <!-- <photo-view :photoList="current.imgList"
              v-model:visible="previewVisible"
              :photoIndex="photoIndex" :reverseImg="reverseImg"></photo-view> -->
  <!-- <section class="add-foot" v-if="(current.ableCancel || current.ableReSubmit) && detailType === 2">
    <a-button @click.prevent="handleCancel" class="cancel-btn">取消</a-button>
    <a-button @click="openModal()" :loading="loading" class="submit-btn" v-if="current.ableCancel">撤销申请</a-button>
    <a-button
      @click="$router.push(`/seal/apply/add?documentId=${$route.query.documentId}&status=2`)"
      :loading="loading"
      class="submit-btn"
      v-if="current.ableReSubmit"
      >重新提交</a-button
    >
  </section> -->
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, createVNode } from 'vue'
import { getApplyDetail, getFingerprintDetail, backoutSeal } from '@/apis/seal'
import { carbonCopyDetails } from '@/apis/approval'
import { RightOutlined } from '@ant-design/icons-vue'
import PhotoView from '@/components/PhotoView'
import '@/style/jsx-label-text.scss'
import { useRoute, useRouter } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import { getDetailsApplyType } from '@/apis/user'
import ApprovalTimeLineList from '@/views/components/approvalTimeLineList'
import { approvalStatusObj, archiveStatusObj, examineStatusObj, sealStatusObj, fileTypeObj } from '@/utils/constData'
//用印视频
import VideoList from '@/components/VideoList'
//弹窗
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import SealStaff from '@/components/sealStaff/index'
const typesObj = {
  1: {
    name: '单次用印',
    color: '#C3161C'
  },
  2: {
    name: '连续用印',
    color: '#5F99FF'
  }
}

const printImgIcon = [
  require('@/assets/images/noPrint.png'),
  require('@/assets/images/tobePrint.png'),
  require('@/assets/images/print.png')
]

export default defineComponent({
  components: {
    // 'label-text': LabelText,
    ApprovalTimeLineList,
    // PhotoView,
    VideoList,
    RightOutlined,
    SealStaff
  },
  setup() {
     var sealStaffRelVOList = [
      {
        staffid: 1,
        staffname: '张三'
      },
      {
        staffid: 2,
        staffname: '李四'
      },
      {
        staffid: 3,
        staffname: '张'
      },
      {
        staffid: 4,
        staffname: '1add1111dadfakfjkasdfjdksajfkasdjfadksfash333'
      },
      {
        staffid: 5,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 6,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 7,
        staffname: '张李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 8,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 9,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 10,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 11,
        staffname: '张李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      },
      {
        staffid: 12,
        staffname: '李四李四李四李四李四李四李四李四李四李四李四李四李四李四'
      }
    ]
    //路由
    const router = useRouter()
    //用印视频
    const iconUrl = 'assets/images/playIcon2.png'

    const route = useRoute()
    const current = ref({ documentFileIds: [], sealFlowShow: [] }) // 详情数据
    const loading = ref(true)
    const previewVisible = ref(false)
    const reverseImg = ref(false)
    const photoIndex = ref(0)
    const isVirtualListScroll = ref(0)
    const state = reactive({
      list: []
    })

    const getImgUrl = src => {
      return require('@/assets/images/' + fileTypeObj[src])
    }
    const getDetail = () => {
      // if (route.query.documentId || route.query.fingerId) {
      //   return getFingerprintDetail({ documentId: route.query.documentId || route.query.fingerId })
      // }
      return carbonCopyDetails({
        documentId: route.query.documentId,
        sealId: route.query.sealId
      })
    }
    //获取数据
    const getList = async () => {
      const res = await getDetail()
      current.value = res.data
      current.value.applyTime = res.data.applyTime.split(' ')[0]
      console.log('current的值', current.value)
      current.value.sealShow &&
        current.value.sealShow.forEach((item, index) => {
          if (item.status == 0) {
            item.icon = printImgIcon[0]
          } else if (item.status == 1) {
            item.icon = printImgIcon[1]
          } else {
            item.icon = printImgIcon[2]
          }
          let arr = []
          res.data.sealFlowShow.forEach(itemChild => {
            if (item.sealId === itemChild.sealId) {
              arr.push(itemChild)
              item.isRecordBtn = itemChild.finishType === 2
            }
          })
          state.list[index] = arr
        })
      console.log('---3----', current.value.sealShow)
      current.value.useCount = route.query.fingerId
        ? 1
        : res.data.sealShow.reduce((acct, item) => acct + item.practicalNumber, 0)
      loading.value = false
      isVirtualListScroll.value++
    }
    onMounted(() => {
      getDetailsType()
      getList()
    })

    const previewImg = (src, i, type) => {
      if (!src) return
      reverseImg.value = false
      current.value.imgList = []
      if (type == 'printImg') {
        current.value.documentFileRelVO?.map(item => {
          if (item.fullFile) current.value.imgList.push(item.fullFile)
        })
        reverseImg.value = true
      } else if (type == 'applyFile') {
        current.value.documentApplyFileS?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            current.value.imgList.push(item.fullFile)
          }
        })
      } else if (type == 'remoteFile') {
        // current.value.imgList = [].concat(current.value.documentApplyFileS)
        current.value.documentRemoteVice?.map(item => {
          current.value.imgList.push(item.fullFile)
        })
      }
      photoIndex.value = current.value.imgList.indexOf(src)
      previewVisible.value = true
    }
    const reImg = (e, src, sign) => {
      console.log('12312312')
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      if (sign === 'a') {
        const tipsText = document.createTextNode('点击下 载该附件查看')
        tipsSpan.appendChild(tipsText)
        tipsSpan.addEventListener('click', () => {
          window.location.href = src.replace('.com//', '.com/') + '?upload=true'

          setTimeout(() => {
            cmsNotice('success', '正在为你下载，请耐心等待')
          }, 200)
        })
        parentNode.removeChild(e.target)
        parentNode.appendChild(tipsSpan)
      } else {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '104px'
        tipsDiv.style.width = '104px'
        tipsDiv.style.background = '#F2F2F2'
        tipsDiv.style.textAlign = 'center'
        // tipsDiv.style.transform = 'rotateZ(180deg)'
        tipsDiv.style.color = '#B1B1B1'
        tipsDiv.style.padding = '40px 0 0 0'
        const tipsI = document.createElement('i')
        const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
        tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
        tipsI.style.fontSize = '30px'
        tipsI.style.color = '#D9D9D9'
        tipsI.style.marginBottom = '10px'
        tipsI.style.height = '25px'
        tipsI.style.display = 'block'
        tipsDiv.appendChild(tipsI)
        tipsDiv.appendChild(tipsText)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
      }

      // parentNode.removeChild(e.target)
      // parentNode.appendChild(tipsSpan)
    }

    // 是否为企业版 列表数据不同
    const detailType = ref()
    const getDetailsType = async () => {
      const res = await getDetailsApplyType()
      detailType.value = res.data
      console.log(detailType.value)
    }

    const downloadFile = src => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'
      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    }
    //取消
    const handleCancel = () => {
      router.back()
    }
    //撤销申请
    const openModal = () => {
      console.log('currentId', route.query.documentId)
      Modal.confirm({
        title: '是否撤销！',
        content: '请确认是否执行【撤销申请】操作?',
        centered: true,
        confirmLoading: state.loading,
        icon: createVNode(ExclamationOutlined),
        onOk: async () => {
          const res = await backoutSeal({ id: route.query.documentId })
          if (res.success) {
            cmsNotice('success', '您的申请已撤销成功！')
            getList()
          } else if (res.code === 1068) {
            // cmsNotice('error', '用印申请已撤销！')
            router.push('/seal/apply')
          }
        }
      })
    }

    return {
      iconUrl,
      getDetailsType,
      downloadFile,
      getImgUrl,
      fileTypeObj,
      current,
      loading,
      ...toRefs(state),
      approvalStatusObj,
      examineStatusObj,
      sealStatusObj,
      archiveStatusObj,
      typesObj,
      previewVisible,
      reverseImg,
      previewImg,
      reImg,
      detailType,
      isVirtualListScroll,
      photoIndex,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      },
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      handleCancel,
      openModal,
      getList,
      sealStaffRelVOList
    }
  }
})
</script>

<style lang="scss" scoped>
// :deep(.ant-spin-container) {
//   margin-top: -24px;
// }
.seal-detail {
  margin-top: -36px;
  .label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    vertical-align: top;
  }
  .value {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    display: inline-block;
    flex: 0.9;
  }
  .value-wrap {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap !important;
    vertical-align: top;
    width: 70%;
  }
  .value-omit {
    width: 70%;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .seal-title {
    border: none;
    :deep(.ant-card-body) {
      padding: 4px 24px 12px;
    }
    .seal-status {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
    }
  }
  .dotted {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    transform: translateY(-2px);
  }

  .no-record {
    width: 300px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 10% auto;
    > img {
      width: 300px;
      height: 266px;
      margin-bottom: 8px;
    }
  }
}

.seal-tabs {
  padding: 16px 24px 0;
  .applyFileBox {
    display: flex;
    align-items: center;
    &:hover .downloadFileBox {
      display: block;
    }
    .downloadFileBox {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      height: 35px;
      line-height: 35px;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .stamped-mage {
    width: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 26px;
    margin-bottom: 10px;
  }
  .seal-imgs {
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // padding: 8px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 0;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
    .seal-img {
      width: 108px;
      height: 108px;
    }
  }
  .seal-no-img {
    width: 104px;
    height: 104px;
  }
  .process {
    :deep(.ant-card-head-wrapper) {
      align-items: flex-start;
    }
    .card-title {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      .card-title-item {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(0, 0, 0, 0.65);
      }
      .card-title-items {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .tab-btn {
      width: 80px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #0A7BFF;
      :deep(.anticon) {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.show-content {
  .show-img-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .show-img-div {
      width: 104px;
      height: 104px;
      margin-right: 16px;
      margin-bottom: 8px;
      .show-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}
:deep(div.image-list-module) {
  //视频
  .video-box {
    position: relative;
    width: 104px;
    height: 104px;
    border: none;
    padding: 0;
    margin-right: 16px;
    margin-bottom: 8px;
    .min-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .play-image {
      width: 19px;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}
// 定位
.seal-headline {
  // position: relative;
  .seal-item {
    // position: absolute;
    // top: 0;
    // left: 19.125rem;
  }
}
.single-wrap {
  width: 10em;
}

//取消/撤销申请
.add-foot {
  width: calc(100vw - 208px);
  padding: 12px 24px;
  text-align: right;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
  .cancel-btn,
  .cancel-btn:hover,
  .cancel-btn:focus,
  .cancel-btn:active,
  .cancel-btn.active {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;
    border-radius: 2px;
  }
  .submit-btn {
    background-color: #c3161c;
    border-radius: 2px;
    border: none;
    color: #ffffff;
  }
}
</style>

<style lang="less" scoped>
.info-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 27px;

  .info-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
  .applicant {
    display: flex;
    flex-direction: row;
    align-items: center;
    .special {
      width: 4em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .info-content {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  .content-wrap {
    width: 13em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
.seal-date {
  min-width: 155px;
}
.seal-way {
  margin-left: 101px;
}
.status {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
</style>
<style lang="less">
//提示
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }
  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>
