<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 15:39:05
 * @FilePath: \yda_web_manage\src\views\seal\applyList\detail_3.vue
 * @Description: 用印记录详情3.0
 * 
-->
<template>
  <div class="apply-record-detail">
    <!-- 头部信息 -->
    <DetailHeader
      :headerInfo="resData"
      :showSealStatus="false"
      :showApplyStatus="false"
      :showSealRecord="false"
      :showSection="true"
      :showCopy="false"
    ></DetailHeader>
    <div class="content">
      <DetaiForm :formInfo="formInfo" />
      <!-- 审批信息 -->
      <div class="approval-info" v-if="approvalInfo.length > 0">
        <div class="model-title">审批信息</div>
        <ApprovalFlow
          :approvalInfo="approvalInfo"
          :isApprove="true"
        ></ApprovalFlow>
      </div>
    </div>
    <section class="add-foot" v-if="showProcessTerminate">
      <a-button @click.prevent="$router.back()" class="cancel-btn"
        >取消</a-button
      >
      <a-button
        @click="confirmEnd"
        type="primary"
        :loading="endLoading"
        class="end-btn"
        >结束流程</a-button
      >
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, createVNode } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { sealDetail, endApply, recordDetail } from '@/apis/seal'
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
export default defineComponent({
  components: {
    DetailHeader,
    DetaiForm,
    ApprovalFlow
  },
  setup() {
    const route = useRoute()
    //接口源数据
    const resData = ref({})
    // 表单部分信息
    const formInfo = ref({})
    //审批信息
    const approvalInfo = ref([])
    // 结束流程的loading
    const endLoading = ref(false)
    const showProcessTerminate = ref(false)
    //获取详情
    const getDetail = () => {
      let params = {
        documentId: route.query.documentId,
        processInstanceId: route.query.processInstanceId
      }
      sealDetail(params)
        .then((res) => {
          if (res.success) {
            if (res.data.sealStatus == 2) {
              recordDetail(params).then((res) => {
                if (res.success) {
                  resData.value = res.data
                  showProcessTerminate.value = res.data.showProcessTerminate
                  console.log('66666666', showProcessTerminate.value)
                  approvalInfo.value = res.data.flowNodes
                    ? res.data.flowNodes
                    : []
                  formInfo.value = {
                    formData: res.data.formData,
                    formItems: res.data.formItems,
                    hasArchaic: res.data.hasArchaic,
                    fileElseList: res.data.fileElseList,
                    hasCover: res.data.hasCover,
                    documentId: route.query.documentId,
                    fileCheckList: res.data?.fileCheckList || null,
                    sealStatus: res.data?.sealStatus,
                    codeData: {
                      code: res.data?.code,
                      sealStatus: res.data?.sealStatus
                    },
                    qrcodeData: {
                      qrcodeDiffList: res.data.qrcodeDiffList,
                      qrcodeType: res.data.qrcodeType,
                      qrcodePositionType: res.data.qrcodePositionType,
                      qrcodeSize: res.data.qrcodeSize
                    }
                  }
                }
              })
            } else {
              resData.value = res.data
              showProcessTerminate.value = res.data.showProcessTerminate
              console.log('66666666', showProcessTerminate.value)
              approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
              formInfo.value = {
                formData: res.data.formData,
                formItems: res.data.formItems,
                hasArchaic: res.data.hasArchaic,
                fileElseList: res.data.fileElseList,
                hasCover: res.data.hasCover,
                documentId: route.query.documentId,
                fileCheckList: res.data?.fileCheckList || null,
                sealStatus: res.data?.sealStatus,
                codeData: {
                  code: res.data?.code,
                  sealStatus: res.data?.sealStatus
                },
                qrcodeData: {
                  qrcodeDiffList: res.data.qrcodeDiffList,
                  qrcodeType: res.data.qrcodeType,
                  qrcodePositionType: res.data.qrcodePositionType,
                  qrcodeSize: res.data.qrcodeSize
                }
              }
            }
            // console.log('66666666', res.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    onMounted(() => {
      getDetail()
    })
    const confirmEnd = () => {
      Modal.confirm({
        title: '是否结束流程！',
        icon: createVNode(ExclamationOutlined),
        content: '流程结束后将不能继续审批/用印，且不能恢复',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onCancel() {},
        async onOk() {
          endLoading.value = true
          await endApply(route.query.documentId)
            .then((res) => {
              if (res.success) {
                setTimeout(() => {
                  endLoading.value = false
                }, 500)
                cmsNotice('success', '您的流程已结束！')
                getDetail() //停留在当前页
              } else {
                setTimeout(() => {
                  endLoading.value = false
                  getDetail()
                }, 500)
              }
            })
            .catch((err) => {
              console.log(err)
              setTimeout(() => {
                endLoading.value = false
              }, 500)
            })
        }
      })
    }

    return {
      resData,
      formInfo,
      approvalInfo,
      getDetail,
      confirmEnd,
      showProcessTerminate
    }
  }
})
</script>

<style lang="scss" scoped>
.apply-record-detail {
  .content {
    padding: 16px 24px 16px;
    .approval-info {
      background-color: #fff;
      .model-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 16px 24px;
        border-bottom: 1px #e9e9e9 solid;
      }
    }
  }
}
.add-foot {
  width: calc(100vw - 208px);
  padding: 12px 24px;
  text-align: right;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

  .cancel-btn,
  .cancel-btn:hover,
  .cancel-btn:focus,
  .cancel-btn:active,
  .cancel-btn.active {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;
    border-radius: 2px;
  }

  .submit-btn {
    background-color: #0A7BFF;
    border-radius: 2px;
    border: none;
    color: #ffffff;
  }

  .end-btn {
    margin-right: 8px;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid #0A7BFF;
    color: #0A7BFF;
  }
}
</style>
