<!--
 * @Author: cyx 
 * @Date: 2024-02-22 14:53:12
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-13 11:17:27
 * @FilePath: /yda_web_manage/src/components/detailForm/components/qrCode/qrCodeList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<!-- 二维码校验记录 -->
<template>
  <div v-if="qrcodeDiffList?.length > 0" class="box-card">
    <p class="text-title">二维码校验记录</p>
    <div class="box-item-card" v-viewer="printImgOptions">
      <div
        :class="qrcodeDiffList?.length > 0 ? 'box-card-child-margin' : 'box-card-child'"
        v-for="item in qrcodeDiffList"
        :key="item.documentId"
      >
        <div class="box-img">
          <img class="img" :src="item.fullFile" alt="" />
          <span :style="setTextLabel(item.qrcodeType).style" class="text-label">{{
            setTextLabel(item.qrcodeType).text
          }}</span>
        </div>
        <p class="text-staffname">{{ item.staffName }}</p>
        <p class="text-time">{{ item.createTime }}</p>
        <p>{{ item.address }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
const props = defineProps({
  qrcodeDiffList: {
    type: Array,
    default: () => [],
    required: true
  }
})

const printImgOptions = {
  navbar: false,
  title: false,
  transition: false,
  viewed(e) {
    if (e.detail.originalImage.dataset.imagetype == 1) {
      this.viewer.rotateTo(0)
    }
  }
}

// 设置二维码类型标签文字、背景色
const setTextLabel = type => {
  let _background = '#00B66E'
  let _text = '检验通过'
  let _horizontalPadding = 16
  if (type == 2) {
    _background = '#9F9F9F'
    _text = '未检测到二维码'
    _horizontalPadding = 7
  } else if (type == 3) {
    _background = '#C3161C'
    _text = '违规二维码'
    _horizontalPadding = 9
  }
  return {
    text: _text,
    style: `background:${_background};padding: 3px ${_horizontalPadding}px;`
  }
}
</script>
<style scoped lang="less">
.box-card {
  width: 100%;
  background: #ffffff;
  border-radius: 2px;
  margin-top: 16px;
  padding: 24px;
  border-top: 1px solid #e9e9e9;

  .text-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }

  .box-item-card {
    display: flex;
    flex-wrap: wrap;

    .box-card-child,
    .box-card-child-margin {
      margin-top: 8px;
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        text-align: center;
      }

      .box-img {
        width: 160px;
        height: 104px;
        position: relative;

        .img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .text-label {
          display: inline-block;
          border-radius: 0px 8px 8px 8px;
          position: absolute;
          left: 0;
          top: 0;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }
      .text-staffname{
          max-width: 84px;
          margin-top: 4px;
          white-space:nowrap;// 不换行
          overflow:hidden;// 溢出隐藏
          text-overflow:ellipsis; // 超出显示省略号
        }

      .text-time {
        margin-bottom:  4px;
      }
    }

    .box-card-child-margin {
      margin-right: 32px;
    }
  }
}
</style>
