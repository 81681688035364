<!--  扫描仪v1.0 -->
<template>
  <div class="file-check">
    <p class="title-check">文件校验</p>
    <div class="file-box">
      <div
        :class="[$attrs.value.length > 1 ? 'file-module-mult' : 'file-moudle-single', 'file-module']"
        v-for="(i, idx) in $attrs.value"
        :key="i.fileId"
      >
        <div class="file-top">
          <div class="file-name-box">
            <img v-if="i.suffix" class="file-img" :src="getImgUrl(i)" alt="" />
            <a-tooltip v-if="i.name && i.name.length > 24" :arrowPointAtCenter="true">
              <template #title>{{ i.name }}</template>
              {{ i.name.slice(0, 24) + '...' }}
            </a-tooltip>
            <span v-else class="file-title">{{ i.name + '.' + i.suffix }}</span>
          </div>
          <div class="file-click" @click="checkPreview(idx)">
            查看
          </div>
        </div>
        <div :class="{'file-ope': $attrs.value.length===1}">
          <div :class="[$attrs.value.length > 1 ? '':'file-bottom-w','file-bottom']" v-for="(item, index) in i.operationList" :key="index">
            <span>操作人：</span>
            <span class="ope-person" v-if="item.operationName.length > 4">{{
              item.operationName.slice(0, 4) + '...'
            }}</span>
            <span class="ope-person" v-else>{{ item.operationName }}</span>
            <span class="ope-time">第{{ index + 1 }}次校验时间：{{ item.operationTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <FileCheckOCR :key="state.refshKey" v-model:visible="state.visible" :allFileList="state.allFileList" :applyFileName="$attrs.fileName" :selectFileIndex="state.selectFileIndex" ref="ocrDiff"></FileCheckOCR>
  </div>
</template>

<script setup>
import { onMounted, ref, useAttrs, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import FileCheckOCR from './fileCheckOCR.vue'
import { fileCheckDetail } from '@/apis/seal'

const route = useRoute()
const nums = ref([
  {
    fileTitle: '市场部文件',
    fileType: 'pdf',
    operateList: [
      { opePerson: '张某', time: '2024-01-23 16:00:00' },
      { opePerson: '张某就换个环境', time: '2024-01-24 16:00:00' }
    ]
  },
  {
    fileTitle: '软件部文件',
    fileType: 'word',
    operateList: [
      { opePerson: '小李', time: '2024-01-23 16:00:00' },
      { opePerson: '小王', time: '2024-01-29 16:00:00' }
    ]
  }
])
const state = reactive({
  visible: false,
  ocrFileVOList: [],
  allFileList: [],
  selectFileIndex: 0,
  refshKey: ''
  // applyFileName: ''
})
const ocrDiff = ref(null)

const isDetail = ref(false) // 是否是申请详情页
const attrs = computed(() => useAttrs())

onMounted(() => {
  console.log('attrsfilecheck', attrs.value)
  isDetail.value = route.path === '/seal/apply/detail'
})
// const code = computed(() => {
//   const data = attrs.value.value
//   return !data.codeData
//     ? {
//         status: false // 只有待用印才是ture
//       }
//     : {
//         value: data.codeData.code,
//         status: data.codeData.sealStatus === 1 || data.codeData.sealStatus === 2 // 只有待用印才是ture
//       }
// })
//文件格式对应的图标
const getImgUrl = computed(() => i => {
  const obj = {
    docx: require('@/assets/images/word.png'),
    doc: require('@/assets/images/word.png'),
    xls: require('@/assets/images/excel.png'),
    xlsx: require('@/assets/images/excel.png'),
    pdf: require('@/assets/images/pdf.png'),
    text: require('@/assets/images/txt.png')
  }
  return obj[i.suffix] ? obj[i.suffix] : i.fullFile
})
const checkPreview = idx => {
  console.log('attrs', attrs)
  fileCheckDetail({ documentId: attrs.value.documentId }).then(res => {
    if(res.success) {
      // state.ocrFileVOList = res.data
      console.log('ocrFileVOListocrFileVOList', res.data)
      state.allFileList = res.data
      state.selectFileIndex = idx
      state.refshKey = Math.random()
    }
  })
  state.visible = true
}
</script>
<style scoped lang="less">
.file-check {
  .file-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .title-check {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .file-module::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    display: inline-block;
  }
  .file-module::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
  }
  .file-module-mult {
    width: 49%;
  }
  .file-moudle-single {
    width: 100%;
    &:first-child {
      margin-right: 0 !important;
    }
  }
  .file-module {
    max-height: 184px;
    height: 174px;
    background: #ffffff;
    border: 1px solid #ececec;
    padding: 16px;
    margin: 16px 0px 0;
    overflow-y: scroll;
    &:nth-child(odd) {
      margin-right: 16px;
    }
    .file-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file-name-box {
        display: flex;
        align-items: center;
      }
      .file-img {
        width: 40px;
        height: 40px;
      }
      .file-title {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-left: 16px;
      }
      .file-click {
        font-weight: 400;
        font-size: 14px;
        color: #0f83ff;
        cursor: pointer;
      }
    }
    .file-ope {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .file-bottom {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      height: 40px;
      background: #f5f6f7;
      line-height: 40px;
      margin-top: 10px;
      padding-left: 16px;

      .ope-person {
        width: 68px;
        display: inline-block;
      }
      .ope-time {
        margin-left: 52px;
      }
    }
    .file-bottom-w {
      width: 49.5%;
    }
  }
}
</style>
