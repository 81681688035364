<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-25 20:26:00
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-11-13 15:09:42
 * @FilePath: \yda_web_manage\src\components\detailForm\components\customFormItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="custom-form-item">
    <div class="title">{{ $attrs.titles }}：</div>
    <!-- 外带地点 -->
    <div v-if="$attrs.titles === '外带地点'" class="address-area">
      <div v-if="$attrs.value && $attrs.value.length" class="address-box">
        <div v-for="(item, index) in $attrs.value" :key="index" class="address-item">{{ item.address }}</div>
      </div>
      <div v-else>无</div>
    </div>
    <div v-else>{{ $attrs.value || '无' }}</div>
  </div>
</template>

<script setup>
import { useAttrs, onMounted } from 'vue'

const attrs = useAttrs()

onMounted(() => {
  console.log('attr的值', attrs)
})
</script>

<style scoped lang="less">
.custom-form-item {
  display: flex;
  width: 100%;
  div {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
  }
  .title {
    flex-shrink: 0;
  }
  margin-bottom: 8px;
}
.address-area {
  width: 100%;
  .address-box {
    max-height: 100px;
    width: 100%;
    background: rgba(216, 216, 216, 0);
    border: 1px solid #e4e4e4;
    padding: 8px 8px 0;
    overflow-y: auto;
    .address-item {
      margin-bottom: 8px;
    }
  }
}
</style>
