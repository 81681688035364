<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 15:39:05
 * @FilePath: \yda_web_manage\src\views\seal\applyList\detail_3.vue
 * @Description: 用印记录详情3.0
 * 
-->
<template>
  <div class="apply-record-detail">
    <!-- 头部信息 -->
    <DetailHeader
      :headerInfo="resData"
      :showSealStatus="false"
      :showApplyStatus="false"
      :showSealRecord="false"
      :showSection="true"
      :showCopy="false"
    ></DetailHeader>
    <div class="content">
      <DetaiForm :formInfo="formInfo" />
      <!-- 归档信息 -->
      <FileArchive :archiveInfos="archiveInfos" v-if="archiveInfos" />
      <!-- 审批信息 -->
      <div class="approval-info" v-if="approvalInfo?.length > 0">
        <div class="model-title">审批信息</div>
        <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true" :isApplyDetail="true"></ApprovalFlow>
      </div>
    </div>
    <!-- <div class="footer">
      <a-button type="primary" v-if="ableSubmitArchive" @click="handleOk(false)" class="footer-ok">归档</a-button>
      <a-button type="primary" v-if="ableReSubmitArchive" @click="handleOk(true)" class="footer-ok">重新归档</a-button>
    </div> -->
    <div class="drawer-box">
      <a-drawer
        title="归档"
        v-model:visible="visible"
        :width="553"
        placement="right"
        :getContainer="false"
        :closable="true"
        :mask="false"
        :maskStyle="{ pointerEvents: 'auto' }"
        @close="this.$refs.archiveDrawerRef.close()"
        class="drawer"
      >
        <drawer
          ref="archiveDrawerRef"
          v-if="visible"
          :visible="visible"
          :closevisible="closevisible"
          title="归档"
          :footer="true"
          :archiveRecord="archiveRecord"
        />
      </a-drawer>
    </div>
    <!-- <a-modal :title="null" :footer="null" :visible="promptOpen" centered @cancel="promptOK">
      <div class="modal">
        <div class="modal-header">
          <ExclamationOutlined class="icon" />
          <span>当前无法归档</span>
        </div>
        <div class="modal-main">
          <p v-for="item in promptStatus" :key="item.index">{{ item }}</p>
        </div>
        <div class="footer">
          <a-button type="primary" @click="promptOK">关闭</a-button>
        </div>
      </div>
    </a-modal> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, provide } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import drawer from './components/archiveDrawer.vue'
import { recordDetail, getNeedMakeUp } from '@/apis/seal'
import { useRoute } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import FileArchive from '@/components/Archive/index.vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    DetailHeader,
    DetaiForm,
    ApprovalFlow,
    drawer,
    FileArchive
  },
  setup() {
    const route = useRoute()
    //接口源数据
    const resData = ref({})
    // 表单部分信息
    const formInfo = ref({})
    //审批信息
    const approvalInfo = ref([])
    //归档弹窗
    const visible = ref(false)
    //归档信息
    const archiveInfos = ref([])
    //归档按钮
    const ableSubmitArchive = ref(false)
    const ableReSubmitArchive = ref(false)
    //弹窗
    // const promptOpen = ref(false)
    const promptStatus = ref([])
    const archiveRecord = ref({
      fingerprint: false
    })
    const closevisible = () => {
      visible.value = false
    }
    const handleOk = re => {
      promptStatus.value = []
      getNeedMakeUp(route.query.documentId).then(res => {
        console.log(res.data)
        if (res.data.makeUpStatus) {
          if (res.data.oneself) {
            cmsNotice('error', `您还有文件未补拍，请补拍后再进行归档操作`)
          } else {
            cmsNotice('error', `该流程【${res.data.staffNames}】未进行补拍，请补拍后进行归档操作`)
          }
        } else if (res.data.record) {
          cmsNotice('error', `当前流程正在进行补盖操作,无法归档`)
        } else {
          if (re) {
            console.log('重新归档666')
            archiveRecord.value.ableReSubmitArchive = true
            archiveRecord.value.documentId = route.query.documentId
          }
          visible.value = true
        }
      })
    }
    // const promptOK = () => {
    //   promptOpen.value = false
    // }
    //获取详情
    const getDetail = () => {
      let params = {
        documentId: route.query.documentId,
        processInstanceId: route.query.processInstanceId
      }
      recordDetail(params)
        .then(res => {
          console.log('66666666', res)
          if (res.success) {
            resData.value = res.data
            archiveRecord.value = res.data
            ableSubmitArchive.value = res.data.ableSubmitArchive
            ableReSubmitArchive.value = res.data.ableReSubmitArchive
            approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
            archiveInfos.value = res.data.documentArchiveDetailsVO
            console.log('归档信息', archiveInfos.value)
            formInfo.value = {
              formData: res.data.formData,
              formItems: res.data.formItems,
              hasArchaic: res.data.hasArchaic,
              fileElseList: res.data.fileElseList,
              hasCover: res.data.hasCover,
              documentId: route.query.documentId,
              fileCheckList: res.data?.fileCheckList || null,
              qrcodeData: {
                qrcodeDiffList: res.data.qrcodeDiffList,
                qrcodeType: res.data.qrcodeType,
                qrcodePositionType: res.data.qrcodePositionType,
                qrcodeSize: res.data.qrcodeSize
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    onMounted(() => {
      getDetail()
      if (route.query.open) {
        console.log('跳转的传参', route.query)
        if (route.query.re == 'true') {
          console.log('重新归档')
          handleOk(true)
        } else {
          console.log('归档')
          handleOk(false)
        }
      }
    })
    provide('getDetail', getDetail)
    return {
      resData,
      formInfo,
      approvalInfo,
      getDetail,
      closevisible,
      handleOk,
      visible,
      archiveInfos,
      ableSubmitArchive,
      ableReSubmitArchive,
      promptStatus,
      archiveRecord
    }
  }
})
</script>

<style lang="scss" scoped>
.apply-record-detail {
  width: 100%;
  position: relative;

  .content {
    padding: 16px 24px 16px;

    .approval-info {
      background-color: #fff;
      margin-top: 16px;
      .model-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 16px 24px;
        border-bottom: 1px #e9e9e9 solid;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #ebedf0;
    background-color: #fff;
    padding: 10px 24px;
    width: 100%;
    text-align: right;

    .footer-ok {
      margin-right: 210px;
    }
  }
}
.drawer-box {
  :deep(.ant-form-item-control) {
    line-height: inherit;
    .ant-calendar-picker {
      width: 100% !important;
    }
  }
  :deep(.ant-drawer-header) {
    position: fixed;
    width: 533px;
    z-index: 2;
  }
  :deep(.ant-drawer-body) {
    margin-top: 54px;
  }
}
.modal {
  .modal-header,
  .modal-text {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0px;

    .icon {
      background-color: #faad14;
      color: #fff;
      border-radius: 50%;
      padding: 3px 0px;
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }

    .tip {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
      margin-left: 8px;
    }

    .tip-text {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
    }
  }

  .modal-text {
    margin: 8px 0;
  }

  .modal-main {
    margin-left: 20px;

    .main-item {
      background-color: #f5f6f7;
      border-radius: 2px;
      padding: 24px;
      font-size: 14px;
      margin-bottom: 8px;

      .item-header {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
      }

      .item-content {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 10px;
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 16px;
  }
}
.ant-drawer-mask {
  display: none !important;
}
</style>

<style lang="scss">
.drawer {
  .ant-drawer-body {
    padding: 0;
  }
}
</style>
