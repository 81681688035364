import { createVNode } from 'vue'
import  * as $Icon from '@ant-design/icons-vue'
// export const Icon = (props: { icon: string }) => {
//     const { icon } = props;
//     return createVNode($Icon[icon]);
// };
export const Icon = function(props) {
    // if (typeof props.icon !== 'string') {
    //     // throw new Error('`icon`必须是字符串类型');
    //     return null;
    // }
    const { icon } = props;
    return createVNode($Icon[icon]);
};