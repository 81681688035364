/*
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-25 19:57:50
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 10:35:20
 * @FilePath: \yda_web_manage\src\components\detailForm\componentExport.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import FileName from './components/fileName.vue'
import CustomFormItem from './components/customFormItem.vue'
import FileUpload from './components/fileUpload.vue'
import GreyBottomCard from './components/greyBottomCard.vue'
import FileCheck from './components/fileCheck.vue'
import SealInfo from './components/sealList/sealList.vue'
import FillConver from './components/sealList/components/fillCover.vue'
import ImageInfoList from './components/sealList/components/imageInfoList.vue'
import QrCodeList from './components/qrCodeList.vue'
import TaskCode from './components/taskCode.vue'


export default {
  FileName,
  CustomFormItem,
  FileUpload,
  GreyBottomCard,
  FileCheck,
  SealInfo,
  FillConver,
  ImageInfoList,
  QrCodeList,
  TaskCode
}
