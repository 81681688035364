<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-25 19:47:57
 * @LastEditors: 杨慢慢
 * @LastEditTime: 2024-01-11 11:43:31
 * @FilePath: \yda_web_manage\src\components\detailForm\fileName.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="file-name">
    <div class="file-name-box">
      <p class="title">{{ $attrs.titles }}</p>
      <p class="content">{{ $attrs.value.name }}</p>
    </div>
    <div
      :class="[
        'file-name-right',
        code.status ? 'file-name-right-blue' : 'file-name-right-grey'
      ]"
      v-if="code.value !== null && isDetail"
    >
      盖印码：{{ code.value }}
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, useAttrs, computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const isDetail = ref(false) // 是否是申请详情页
const attrs = computed(() => useAttrs())

onMounted(() => {
  console.log('code.value', code.value)
  isDetail.value =
    route.path === '/seal/apply/detail' ||
    route.path === '/seal/applyWaitList/detail'
})
const code = computed(() => {
  const data = attrs.value.value
  console.log('attrs.value.value', attrs.value.value)
  return !data.codeData
    ? {
        status: false // 只有待用印才是ture
      }
    : {
        value: data.codeData.code,
        status: data.codeData.sealStatus === 1 || data.codeData.sealStatus === 2 // 只有待用印才是ture
      }
})
</script>
<style scoped lang="less">
.file-name {
  display: flex;
  justify-content: space-between;
  .file-name-box {
    .title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 20px;
      margin-bottom: 8px;
    }
    .content {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
  }
  .file-name-right {
    padding: 6px;
    margin-top: 12px;
    height: 32px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 20px;
  }
  .file-name-right-blue {
    color: #0f83ff;
    border: 1px solid #0f83ff;
    background-color: rgba(15, 131, 255, 0.08);
  }
  .file-name-right-grey {
    text-decoration: line-through;
    color: #999999;
    border: 1px solid #999999;
    background-color: rgba(153, 153, 153, 0.08);
  }
}
</style>
