<template>
  <section class="seal-tabs">
    <not-approval-table />
  </section>
</template>
  <script>
import { defineComponent, onMounted, ref } from 'vue'
import notApprovalTable from '../components/notApprovalTable'
import { permissionCheck } from '@/apis/sealManage.js'
export default defineComponent({
  components: {
    notApprovalTable
  },
  setup() {
    const activeKey = ref('2')
    //判断用户是否有待审批的权限
    const getPermissionCheck = () => {
      permissionCheck({
        code: 'PC_APPROVED_TODO'
      })
        .then(res => {
          activeKey.value = res.data ? '2' : '1'
        })
        .catch(err => {})
    }
    onMounted(() => {
      getPermissionCheck()
    })
    return {
      activeKey
    }
  }
})
</script>
  
  <style lang="scss" scoped>
.seal-tabs {
  // margin-top: -36px;
  padding: 0 24px;

  :deep(.ant-tabs-nav-container) {
    background-color: #ffffff;
    padding: 0 24px;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab) {
    padding: 13px 0;
    font-size: 14px;
  }
  :deep(.ant-tabs-bar) {
    margin: 0;
  }
  :deep(.ant-tabs-tabpane-active) {
    padding: 24px 24px 0;
  }
}
</style>
  