<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 17:08:58
 * @FilePath: \yda_web_manage\src\views\seal\apply\detail_3.vue
 * @Description: 用印申请详情3.0
 * 
-->
<template>
  <div class="seal-detail">
    <!-- 头部信息 -->
    <DetailHeader
      :headerInfo="headerInfo"
      :showTime="false"
      :showSection="true"
      :showSealRequire="headerInfo.hasRecords"
    ></DetailHeader>
    <div
      :class="[
        current.ableCancel || current.ableReSubmit || current.showProcessTerminate
          ? 'big-bottom-padding'
          : 'normal-padding'
      ]"
    >
      <DetaiForm :formInfo="formInfo" />
      <!-- 归档信息 -->
      <FileArchive :archiveInfos="archiveInfos" v-if="archiveInfos" />
      <!-- 审批信息 -->
      <div class="approval-info" v-if="headerInfo.flowNodes">
        <div class="model-title">审批信息</div>
        <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true" :isApplyDetail="true"></ApprovalFlow>
      </div>
    </div>
    <!-- 底部按钮 -->
    <section class="add-foot" v-if="current.ableCancel || current.ableReSubmit || current.showProcessTerminate">
      <a-button @click.prevent="$router.back()" class="cancel-btn">取消</a-button>
      <a-button
        @click="confirmEnd"
        type="primary"
        :loading="endLoading"
        class="end-btn"
        v-if="current.showProcessTerminate"
        >结束流程</a-button
      >
      <!-- <a-button @click="revokeVisible = true" :loading="loading" class="submit-btn" v-if="current.ableCancel"
        >撤销申请</a-button
      >
      <a-button @click="resubmitFun" class="submit-btn" v-if="current.ableReSubmit">重新提交</a-button> -->
    </section>
    <!-- 撤销申请弹窗 -->
    <revoke-modal v-model:visible="revokeVisible" title="撤销申请" @bindSubmit="bindSubmit"></revoke-modal>
    <!-- 重新提交弹窗 -->
    <a-drawer
      title="申请用印"
      :width="533"
      :getContainer="false"
      :visible="drawerVisible"
      :maskClosable="false"
      @close="onClose"
      :bodyStyle="bodyStyle"
      :destroyOnClose="true"
    >
      <DiyForm :approvalFlow="approvalFlow" @onClose="onClose" @onSubmit="onClose" @reselect="onReselect" />
    </a-drawer>
    <!-- 选择流程名称弹窗 -->
    <a-modal v-model:visible="bProcessVisible" title="选择流程名称" @ok="selectProcess" @cancel="cancelSelect" centered>
      <a-form layout="inline" ref="modelRef" :model="modelState" :rules="rulesRef">
        <a-form-item label="流程名称：" name="bProcess">
          <a-select
            v-model:value="modelState.bProcess"
            show-search
            allowClear
            class="form-select"
            placeholder="请选择用印流程名称"
            style="width: 334px"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(value, key) in modelState.bOptions"
              :key="key"
              :value="value.processId"
              :label="value.processName"
            >
              {{ value.processName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, createVNode, provide } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import DiyForm from '@/components/diyForm/index.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import revokeModal from './components/revokeModal.vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
import { useDealDiyFormData } from '@/utils/diyFormHooks'
import { useStore } from 'vuex'
import { revocationReason, sealDetail, getProcessDetail, endApply } from '@/apis/seal'
import { availableProcess, isAvailableProcess, canResubmit } from '@/apis/businessManage'
import FileArchive from '@/components/Archive/index.vue'

export default defineComponent({
  components: {
    DetailHeader,
    ApprovalFlow,
    DetaiForm,
    revokeModal,
    DiyForm,
    FileArchive
  },
  setup() {
    //路由
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    // 申请表单DOME
    const modelRef = ref()
    //选择流程名称
    const modelState = reactive({
      bProcess: undefined,
      bOptions: []
    })
    //流程名称校验
    const rulesRef = reactive({
      bProcess: [
        {
          required: true,
          message: '请选择流程名称'
        }
      ]
    })
    //头部固定信息
    const headerInfo = ref({})
    //审批信息
    const approvalInfo = ref([])
    //归档信息
    const archiveInfos = ref({})
    //底部按钮
    const loading = ref(false)
    // 结束流程的loading
    const endLoading = ref(false)
    const state = reactive({
      list: [],
      revokeVisible: false,
      approvalFlow: [],
      drawerVisible: false,
      bProcessVisible: false,
      bodyStyle: { padding: '0px' }
    })
    const current = reactive({
      ableReSubmit: false,
      ableCancel: false,
      showProcessTerminate: false
    })
    const { dealFormDataAndSealData, dealResubmitData } = useDealDiyFormData()
    // 表单部分信息
    const formInfo = ref({})
    //确认撤销
    const bindSubmit = async params => {
      loading.value = true
      revocationReason({
        withdrawCause: params.revoke,
        documentId: route.query.documentId
        // processInstanceId: route.query.processInstanceId
      })
        .then(res => {
          if (res.success) {
            setTimeout(() => {
              loading.value = false
            }, 500)
            cmsNotice('success', '您的申请已撤销成功！')
            getDetail() //停留在当前页
          } else {
            setTimeout(() => {
              loading.value = false
            }, 500)
          }
        })
        .catch(err => {
          console.log(err)
          setTimeout(() => {
            loading.value = false
          }, 500)
        })
    }
    //获取详情数据
    const getDetail = () => {
      sealDetail({
        documentId: route.query.documentId //用印申请文件id
        // processInstanceId: route.query.processInstanceId //流程名称定义id
      })
        .then(res => {
          console.log('用印申请的详情页', res.data)
          headerInfo.value = res.data
          approvalInfo.value = res.data.flowNodes
          current.ableReSubmit = res.data.ableReSubmit
          current.ableCancel = res.data.ableCancel
          archiveInfos.value = res.data.documentArchiveDetailsVO
          current.showProcessTerminate = res.data.showProcessTerminate
          formInfo.value = {
            formData: res.data.formData,
            formItems: res.data.formItems,
            fileElseList: res.data.fileElseList,
            hasCover: res.data.hasCover,
            codeData: {
              sealStatus: res.data.sealStatus,
              code: res.data.code,
              documentCoverUpCodeVOList: res.data.documentCoverUpCodeVOList
            },
            qrcodeData: {
              qrcodeDiffList: res.data.qrcodeDiffList,
              qrcodeType: res.data.qrcodeType,
              qrcodePositionType: res.data.qrcodePositionType,
              qrcodeSize: res.data.qrcodeSize
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    // 重新提交信息查询接口
    const resubmitGetFormData = async id => {
      console.log('4792365', id)
      // 获取之前填写的表单数据
      const res = await canResubmit(id)
      store.commit('SET_RESUBMIT_RECORD', { recordId: id })
      if (res.success) {
        if (res.data.code === 0) {
          if (!res.data.sealList || res.data.sealList.length <= 0) {
            notification['error']({
              message: '提示',
              description: '当前流程名称无印章配置,请先配置印章'
            })
            return
          }
          console.log('---000res.data', res.data)
          state.approvalFlow = res.data.flowInfoVOList
          await dealResubmitData(res.data.formData, res.data.formItems, res.data.sealList, res.data.fileElseList)
          dealFormDataAndSealData(
            res.data.processId,
            res.data.processDefinitionId,
            JSON.parse(res.data.formItems),
            res.data.sealList,
            true,
            res.data.remoteAuditorVO,
            res.data.hasSelf,
            res.data
          )
          state.drawerVisible = true
        } else {
          Modal.warning({
            centered: true,
            title: `业务流程修改`,
            icon: createVNode(ExclamationOutlined),
            content: '当前业务流程有修改，请重新填写',
            okText: '确定',
            onOk() {
              getFormData({ processId: res.data.processId })
            }
          })
        }
        return
      }
      if (res.code === 8003 || res.code === 8004) {
        //业务流程停用|业务流程不可用
        Modal.confirm({
          centered: true,
          title: `${res.code === 8003 ? '业务流程停用' : '业务流程不可用'}`,
          icon: createVNode(ExclamationOutlined),
          content: res.message,
          okText: '重新选择业务流程',
          cancelText: '确定',
          onCancel() {
            store.commit('SET_RESUBMIT_RECORD', { recordId: null })
          },
          onOk() {
            showConfirm()
          }
        })
      }
    }
    // 打开申请弹窗
    const resubmitFun = () => {
      resubmitGetFormData(route.query.documentId)
    }

    const confirmEnd = () => {
      Modal.confirm({
        title: '是否结束流程！',
        icon: createVNode(ExclamationOutlined),
        content: '流程结束后将不能继续审批/用印，且不能恢复',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onCancel() {},
        async onOk() {
          loading.value = true
          await endApply(route.query.documentId)
            .then(res => {
              if (res.success) {
                setTimeout(() => {
                  loading.value = false
                }, 500)
                cmsNotice('success', '您的流程已结束！')
                getDetail() //停留在当前页
              } else {
                setTimeout(() => {
                  loading.value = false
                  getDetail()
                }, 500)
              }
            })
            .catch(err => {
              console.log(err)
              setTimeout(() => {
                loading.value = false
              }, 500)
            })
        }
      })
    }

    // 关闭申请弹窗
    const onClose = status => {
      state.drawerVisible = false
      store.commit('EMPTY_ALL_DATA')
      if (status) router.push('/seal/apply')
    }
    // 打开流程名称弹窗
    const showConfirm = async () => {
      availableProcess()
        .then(res => {
          if (res.data.length === 0) {
            // 没有可用的流程名称
            notification['warning']({
              message: '提示',
              description: '暂无可用流程名称'
            })
            return
          }
          console.log('查询当前员工可用流程名称', res)
          state.bProcessVisible = true
          modelState.bOptions = res.data
        })
        .catch(error => {
          console.log(error)
        })
    }
    //流程名称元数据下拉选择框模糊搜索功能
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    //流程名称弹窗确定事件
    const selectProcess = () => {
      modelRef.value.validate().then(() => {
        //校验当前选择的流程名称是否可用
        isAvailableProcess({ processId: modelState.bProcess })
          .then(res => {
            let obj = { processId: modelState.bProcess }
            notification.destroy()
            state.bProcessVisible = false
            modelState.bProcess = undefined
            if (res.success) return getFormData(obj)
            Modal.confirm({
              centered: true,
              title: `${res.message}`,
              icon: createVNode(ExclamationOutlined),
              okText: '重新选择流程名称',
              onOk() {
                //重新选择流程名称
                showConfirm()
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
        state.bProcessVisible = false
      })
    }
    //流程名称弹窗取消事件
    const cancelSelect = () => {
      modelState.bProcess = undefined
      modelRef.value.clearValidate()
    }
    // 获取动态表单数据
    const getFormData = async obj => {
      const res = await getProcessDetail(obj)
      state.approvalFlow = []
      const recordId = JSON.parse(JSON.stringify(store.state.diyForm.recordId))
      store.commit('EMPTY_ALL_DATA')
      if (recordId) store.commit('SET_RESUBMIT_RECORD', { recordId })
      if (res.code === 0) {
        console.log('---000res.data', res.data)
        dealFormDataAndSealData(
          res.data.processId,
          res.data.processDefinitionId,
          JSON.parse(res.data.formItems),
          res.data.sealList,
          false,
          res.data.remoteAuditorVO,
          res.data.hasSelf,
          res.data
        )
        state.drawerVisible = true
      }
    }
    // 切换流程、重新填写
    const onReselect = type => {
      if (!type) {
        showConfirm()
      } else {
        getFormData({ processId: store.state.diyForm.processId })
      }
    }
    onMounted(() => {
      getDetail()
    })
    onBeforeRouteLeave(() => {
      Modal.destroyAll()
    })
    provide('getDetail', getDetail)
    return {
      headerInfo,
      approvalInfo,
      current,
      loading,
      endLoading,
      modelRef,
      rulesRef,
      modelState,
      bindSubmit,
      getDetail,
      formInfo,
      resubmitFun,
      confirmEnd,
      onClose,
      onReselect,
      filterOption,
      selectProcess,
      cancelSelect,
      archiveInfos,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.seal-detail {
  .big-bottom-padding {
    padding: 17px 24px 56px;
  }

  .normal-padding {
    padding: 17px 24px 16px;
  }

  .approval-info {
    background-color: #fff;
    margin-top: 20px;
    .model-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      padding: 16px 24px;
      border-bottom: 1px #e9e9e9 solid;
    }
  }

  .add-foot {
    width: calc(100vw - 208px);
    padding: 12px 24px;
    text-align: right;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

    .cancel-btn,
    .cancel-btn:hover,
    .cancel-btn:focus,
    .cancel-btn:active,
    .cancel-btn.active {
      color: rgba(0, 0, 0, 0.65);
      margin-right: 8px;
      border-radius: 2px;
    }

    .submit-btn {
      background-color: #0A7BFF;
      border-radius: 2px;
      border: none;
      color: #ffffff;
    }

    .end-btn {
      margin-right: 8px;
      background-color: transparent;
      border-radius: 2px;
      border: 1px solid #0A7BFF;
      color: #0A7BFF;
    }
  }

  :deep(.ant-drawer-header) {
    position: fixed;
    width: 533px;
    z-index: 2;
  }

  :deep(.ant-drawer-body) {
    margin-top: 54px;
  }
}
</style>
