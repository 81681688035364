<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-27 14:54:07
 * @LastEditors: 杨慢慢
 * @LastEditTime: 2024-01-27 17:58:41
 * @FilePath: \yda_web_manage\src\components\detailForm\components\sealList\components\fillCover.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="fill-cover-box" :style="{ background: $attrs.source !== 2 ? '#f5f6f7' : 'none' }">
    <div class="title flex-layout-between" v-if="$attrs.source !== 2">
      <div class="file-nmae-left">
        <div style="margin-left: -7px;">补盖信息</div>
        <span
          :class="['file-name-right', code.status ? 'file-name-right-blue' : 'file-name-right-grey']"
          v-if="code.value != null && isDetail"
          >盖印码：{{ code.value }}</span
        >
      </div>

      <!-- <template v-if="isDetail && code.status">
        <UrgButton @urgOk="handleUrgOk" tipsType="staff" v-bind="$attrs.urgInfo" v-if="$attrs.urgInfo.urge">
        </UrgButton>
        <UrgeRecord v-if="$attrs.urgInfo.urgeRecord" :recordInfo="$attrs.urgInfo.urgeRecord" type="staff"></UrgeRecord>
      </template> -->
    </div>
    <div
      class="info-box"
      :style="{ marginLeft: $attrs.source === 2 ? '0' : '16px' }"
      :class="{ 'bottom-border': $attrs.source !== 3 && $attrs.coverFlowList && $attrs.coverFlowList.length > 0 }"
    >
      <div class="basic-info-box" v-if="$attrs.source !== 2 && $attrs.coverInfo">
        <div
          v-for="(val, key, index) in $attrs.coverInfo"
          :key="index"
          :class="{ applyer: key.includes('申请人'), 'fill-cover': key.includes('盖印人') }"
        >
          {{ key }}：
          <span v-if="!key.includes('次数')">
            <span v-if="key !== '补盖盖印人'">{{ getVal(key, val) }}</span>
            <Tooltip v-else :value="getVal(key, val)" />
          </span>
          <span v-else>{{ getVal(key, val) }}次</span>
        </div>
      </div>
      <component
        v-for="(item, index) in $attrs.causeAndFile"
        :is="components[item.controlName]"
        :key="index"
        v-bind="item"
      />
    </div>
    <div class="approve" v-if="$attrs.source !== 2 && $attrs.coverFlowList && $attrs.coverFlowList.length > 0">
      <div class="titles">审批信息</div>
      <!-- 这里是审批信息组件 -->
      <ApprovalFlow :approvalInfo="$attrs.coverFlowList" :isApprove="true" :isApplyDetail="isDetail" />
    </div>
  </div>
</template>

<script setup>
import components from '../../../componentExport'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import { reactive, toRefs, onBeforeMount, onMounted, computed, ref, useAttrs, inject } from 'vue'
import Tooltip from '@/components/detailForm/components/tooltip.vue'
import { useRoute } from 'vue-router'
import UrgButton from '@/components/UrgButton/index.vue'
import UrgeRecord from '@/components/UrgRecord/index.vue'
import { cmsNotice } from '@/utils/utils'
const isDetail = ref(false) // 是否是申请详情页
const attrs = useAttrs()
const route = useRoute()
const getVal = computed(() => (key, val) => {
  if (key.includes('次数')) {
    return val || 0
  }
  return val || '无'
})
// 催办结果
function handleUrgOk(data) {
  console.log('催办成功吗', data)
}
onMounted(() => {
  isDetail.value = route.path === '/seal/apply/detail'
  console.log('补盖信息', attrs)
  // if(route.path === '/seal/apply/detail') {
  //   getDetail.value = inject('getDetail')
  // }
})
const code = computed(() => {
  console.log('attrs.value------1', attrs)
  if (attrs.codeData?.length === 0 || attrs.codeData === undefined) {
    return { status: false }
  } else {
    const index = attrs.codeData?.findIndex(item => item.sealId === attrs?.sealId)
    console.log('jjjjjjhhhh', index)
    if (index !== -1) {
      return {
        value: attrs.codeData[index].code,
        status: attrs.codeData[index].reStatus === 1 || attrs.codeData[index].reStatus === 2 // 只有待用印或用印中才是ture
      }
    }
  }
})
</script>
<style scoped lang="less">
.fill-cover-box {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 16px;
  overflow: hidden;
  .title {
    padding: 12px 24px;
    // padding-right: 24px;
    // height: 40px;
    // line-height: 40px;
    background: linear-gradient(270deg, #f5f6f7 0%, #e7e9eb 100%);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  .file-nmae-left {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }
  .file-name-right {
    margin-left: 20px;
    font-size: 14px;
    padding: 3px 6px;
    border-radius: 4px;
  }
  .file-name-right-blue {
    color: #0f83ff;
    border: 1px solid #0f83ff;
    background-color: rgba(15, 131, 255, 0.08);
  }
  .file-name-right-grey {
    text-decoration: line-through;
    color: #999999;
    border: 1px solid #999999;
    background-color: rgba(153, 153, 153, 0.08);
  }
  .info-box {
    margin: 16px 24px;
    .basic-info-box {
      display: flex;
      margin-bottom: 8px;
      div {
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      :deep(.tooltip-box) {
        border: none;
        display: inline;
      }
      .applyer {
        max-width: 168px;
      }
      .fill-cover {
        max-width: 586px;
        margin: 0 100px;
      }
    }
    :deep(.custom-form-item) {
      div {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .bottom-border {
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }
  .approve {
    margin: 16px 0px;

    .titles {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
  }
}
.urg-btn {
  padding-top: 0;
}
</style>
