<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-26 10:39:28
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-05 18:29:22
 * @FilePath: \yda_web_manage\src\components\detailForm\components\sealMode.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="[$attrs.titles !== '盖印方式' ? 'seal-mode-box' : '']" v-if="$attrs.titles !== '盖印方式'">
    <div class="title" v-if="$attrs.titles.length > 0 && $attrs.type !== 'REMOTE'">外带信息</div>
    <CustomFormItem v-bind="item" v-for="(item, index) in $attrs.value" :key="index" />
  </div>
</template>

<script setup>
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
import CustomFormItem from './customFormItem.vue'
</script>
<style scoped lang="less">
.seal-mode-box {
  background-color: rgba(245, 246, 247, 1);
  border-radius: 2px;
  padding: 16px 24px;
  margin-bottom: 16px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 16px;
  }

  div:last-child {
    margin-bottom: 0;
  }
}
</style>
