<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 17:11:31
 * @FilePath: \yda_web_manage\src\views\approval\approvalDetail_3\index.vue
 * @Description: 审批详情-待审批/已审批
 * 
-->
<template>
  <template v-if="pageLoading">
    <a-spin size="large" class="loading-animation" />
  </template>
  <template v-else>
    <div class="approval-detail">
      <!-- 头部信息 -->
      <DetailHeader
        :headerInfo="headerInfo"
        :showSealStatus="!hasPending"
        :showApplyStatus="false"
        :showSealRecord="true"
        :showTime="true"
        :hasCover="headerInfo.hasCover"
        :showSection="true"
        :isAppoval="true"
        v-if="headerInfo.processMold == 1"
      />
      <DetailHeader
        :headerInfo="headerInfo"
        :showSealStatus="!hasPending"
        :showApplyStatus="false"
        :showSealRecord="false"
        :showTime="true"
        :hasCover="$route.query.hasCover === 'true'"
        :showSection="true"
        :isAppoval="true"
        v-if="headerInfo.processMold == 2"
      />
      <!-- 内容 -->
      <div
        :class="[
          'defalut-padding',
          hasPending && $route.query.hasCover === 'false' ? 'small-top-padding' : 'big-top-padding',
          hasPending ? 'big-bottom-padding' : 'small-bottom-padding'
        ]"
      >
        <DetaiForm :formInfo="formInfo" />
        <!-- 文件归档 -->
        <ArchiveInfo :archiveInfos="archiveInfos" v-if="archiveInfos" />
        <!-- 审批信息 -->
        <div class="approval-info" v-if="approvalInfo.length > 0">
          <!-- <div class="model-title">审批信息</div> -->
          <div class="model-title">
            <div>审批信息</div>
            <div class="right" @click="addSign()" v-if="countersign">
              <img src="@/assets/images/approval/add.png" alt="" class="img">
              <span>加签</span>
            </div>
          </div>
          <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true"></ApprovalFlow>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="foot-btn" v-if="hasPending">
        <a-button class="btn" @click="$router.go(-1)">取消</a-button>
        <a-button type="primary" ghost class="btn" @click="approveResult('refuse')" :loading="refuseLoading"
          >拒绝</a-button
        >
        <a-button type="primary" @click="approveResult('agree')" :loading="agreeLoading">同意</a-button>
      </div>
      <!-- 审批意见弹窗 -->
      <OpinionModal v-model:visible="modelState.visible" @sbmit="ok"></OpinionModal>
      <!-- 加签弹窗 -->
      <SignModal v-model:visible="signShow" @sbmit="signOk" @onCancel="signCancel" :dataId="headerInfo.dataId" :data="data"></SignModal>
    </div>
  </template>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, toRaw, createVNode, nextTick } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import { useRoute, useRouter } from 'vue-router'
import { approveDetail, approve } from '@/apis/approval'
import OpinionModal from '../components/opinionModal'
import { Modal, notification } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { cmsMessage } from '@/utils/utils'
import ArchiveInfo from '@/components/Archive/index.vue'
import SignModal from '../components/signModal'

export default defineComponent({
  components: {
    DetailHeader,
    ApprovalFlow,
    OpinionModal,
    DetaiForm,
    ArchiveInfo,
    SignModal
  },
  setup() {
    const pageLoading = ref(true)
    const route = useRoute()
    const router = useRouter()
    const refuseLoading = ref(false)
    const agreeLoading = ref(false)
    const signShow = ref(false)

    //审批接口参数
    const data = reactive({
      documentId: undefined, //文件id
      opinion: '', //审批意见
      params: {}, //其他参数
      staffId: undefined, //审批人id
      taskId: route.query.taskId, //审批任务id
      state: undefined, //当前审批人的状态 3同意、4拒绝
      processMold: route.query.processMold
    })
    //审批意见弹窗
    const modelState = reactive({
      visible: false,
      opinion: ''
    })
    //头部固定信息
    const headerInfo = ref({})
    // 表单部分数据
    const formInfo = ref({})
    //全局审批流程信息
    const approvalInfo = ref([])
    const hasPending = ref(true) //当前用户是否处于审批
    const countersign = ref(true) //当前用户是否可以加签

    //有无归档权限
    const hasArchive = route.query.dataId
    //获取待审批/已审批详情
    const archiveInfos = ref({})
    const getDetail = () => {
      approveDetail({
        id: route.query.id,
        taskId: route.query.taskId,
        dataId: route.query.dataId,
        processMold: route.query.processMold
      })
        .then(res => {
          if (res.success) {
            archiveInfos.value = res.data.documentArchiveDetailsVO
            console.log('777777777777777', res.data)
            headerInfo.value = res.data
            const items = JSON.parse(res.data.formItems)
            const index = items.findIndex(item => item.type === 'FILE_NAME')
            if (index > -1) {
              headerInfo.value['fileName'] = res.data.formData[items[index].id][0].fileName || ''
            }
            approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
            data.documentId = res.data.documentId
            data.params = res.data.params
            hasPending.value = res.data.hasPending
            formInfo.value = {
              formData: res.data.formData,
              formItems: res.data.formItems,
              hasArchaic: res.data.hasArchaic,
              fileElseList: res.data.fileElseList,
              hasCover: res.data.hasCover,
              fileCheckList: res.data.processMold == 2 ? res.data.fileCheckList : null,
              documentId: res.data.documentId,
              qrcodeData: {
                qrcodeDiffList: res.data.qrcodeDiffList,
                qrcodeType: res.data.qrcodeType,
                qrcodePositionType: res.data.qrcodePositionType,
                qrcodeSize: res.data.qrcodeSize
              },
              processMold: res.data.processMold
            }
            let isOr = false
            res.data.flowNodes.forEach((element, index) => {
              const ind = element.staffList.findIndex(item => item.approvalStatus == 2)
              if(ind != -1) {
                isOr = res.data.flowNodes[index].mode != 'OR'
              }
            });
            // 有同意按钮/是用印审批/不是补盖审批/是待审批页面
            countersign.value = res.data.hasPending && res.data.processMold == 1 && !res.data.hasCover && route.path.includes('approval/notApproval/pendingdetail') && isOr
            if (res.data.processMold == 2) {
              headerInfo.value.processType = 2
            }
            console.log('自定义表单部分', res.data)
            pageLoading.value = false
          } else {
            pageLoading.value = false
          }
        })
        .catch(error => {
          console.log(error)
          pageLoading.value = false
        })
    }

    //审批接口
    const getApproveResult = data => {
      let params = {
        ...data,
        dataId: headerInfo.value.dataId
      }
      approve(params)
        .then(res => {
          if (res.success) {
            setTimeout(() => {
              refuseLoading.value = false
              agreeLoading.value = false
            }, 500)
            cmsMessage('success', '审批成功')
            if (route.query.notice) {
              router.replace('/approval/notApproval')
            } else {
              router.go(-1)
            }
          } else {
            if (res.code === 8011) {
              //当前用户已审批
              notification.destroy()
              Modal.confirm({
                centered: true,
                title: `本审批节点已通过，请勿重复操作`,
                icon: createVNode(ExclamationOutlined),
                okText: '确定',
                cancelText: '返回审批列表页',
                onOk() {
                  getDetail()
                  router.push({
                    path: '/approval/approved/approveddetail',
                    query: {
                      documentId: route.query.documentId,
                      processInstanceId: route.query.processInstanceId,
                      taskId: route.query.taskId,
                      hasCover: route.query.hasCover
                    }
                  })
                },
                onCancel() {
                  router.go(-1)
                }
              })
            } else if (res.code === 8012) {
              //当前用户已审批
              notification.destroy()
              Modal.confirm({
                centered: true,
                title: `本审批节点已拒绝，请勿重复操作`,
                icon: createVNode(ExclamationOutlined),
                okText: '确定',
                cancelText: '返回审批列表页',
                onOk() {
                  getDetail()
                  router.push({
                    path: '/approval/approved/approveddetail',
                    query: {
                      documentId: route.query.documentId,
                      processInstanceId: route.query.processInstanceId,
                      taskId: route.query.taskId,
                      hasCover: route.query.hasCover
                    }
                  })
                },
                onCancel() {
                  router.go(-1)
                }
              })
            }
            setTimeout(() => {
              refuseLoading.value = false
              agreeLoading.value = false
            }, 500)
          }
        })
        .catch(err => {
          console.log(err)
          setTimeout(() => {
            refuseLoading.value = false
            agreeLoading.value = false
          }, 500)
        })
    }
    //审批 拒绝 | 同意
    const approveResult = type => {
      if (type === 'refuse') {
        //拒绝
        modelState.visible = true
      } else {
        //同意
        agreeLoading.value = true
        data.state = 1
        getApproveResult(toRaw(data))
      }
    }
    //审批意见弹窗 确定
    const ok = opinion => {
      data.state = 2
      data.opinion = opinion
      refuseLoading.value = true
      getApproveResult(toRaw(data))
    }

    // 加签同意
    const signOk = () => {
      signShow.value = false
      approvalInfo.value = []
      // 刷新页面
      // getDetail()
      router.go(-1)
    }
    const signCancel = (code) => {
      signShow.value = false
      if(code == 'ok') {
        getDetail()
        router.push({
          path: '/approval/notApproval',
          query: {
            documentId: route.query.documentId,
            processInstanceId: route.query.processInstanceId,
            taskId: route.query.taskId,
            hasCover: route.query.hasCover
          }
        })
      } else {
        router.go(-1)
      }
    }

    // 加签
    const addSign = () => {
      signShow.value = true
    }

    onMounted(async () => {
      data.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId
      data.taskId = route.query.taskId
      getDetail()
      console.log('看看里面有什么', approvalInfo)
    })
    return {
      headerInfo,
      formInfo,
      approvalInfo,
      getDetail,
      approveResult,
      modelState,
      ok,
      getApproveResult,
      refuseLoading,
      agreeLoading,
      hasPending,
      pageLoading,
      hasArchive,
      archiveInfos,
      data,
      signShow,
      countersign,
      signOk,
      addSign,
      signCancel
    }
  }
})
</script>

<style lang="scss" scoped>
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.approval-detail {
  .defalut-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .big-top-padding {
    padding-top: 16px;
  }

  .small-top-padding {
    padding-top: 16px;
  }

  .big-bottom-padding {
    padding-bottom: 56px;
  }

  .small-bottom-padding {
    padding-bottom: 16px;
  }

  .approval-info {
    background-color: #fff;
    margin-top: 20px;

    .model-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      padding: 16px 24px;
      border-bottom: 1px #e9e9e9 solid;
      display: flex;
      justify-content: space-between;
      .right {
        cursor: pointer;
        color: #1890FF;
        font-size: 14px;
        .img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  .foot-btn {
    width: calc(100vw - 208px);
    padding: 12px 24px;
    text-align: right;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

    .btn {
      margin-right: 8px;
    }
  }
}
</style>
